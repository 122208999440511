.about_ctr {
    text-align: center;
}
.about_ctr .bg--blue7 {
    display: none;
}
.about_ctr .bg--yellow5 {
    display: none;
}

/* section 1 */
.about_ctr .ab_content_w_1 {
    height: 200px;
    margin-bottom: 60px;
    background: url(../../images/img-aboutbanner-draft.jpg) no-repeat center;
    background-size: cover;
    background-clip: padding-box;
}
.about_ctr .ab_content_w_1 #ani_logo {
    position: relative;
    top: 74px;
    margin: 0px auto;
    text-align: center;
}
#ani_logo #logo-left {
    width: 110px;
    height: 50px;
}
#ani_logo #logo-left image {
    width: 110px;
    height: 50px;
}
.ani_box .intro {
    display: inline-block;
    width: 0px;
    height: 50px;
    animation-name: logoslide;
    animation-delay: 0.2s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}
@keyframes logoslide {
    from {
        width: 0px;
    }
    to {
        width: 120px;
    }
}

.ani_box .intro text {
    font-size: 53px;
    animation-name: aniText;
    animation-delay: 0.5s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
}
@keyframes aniText {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.ani_box .intro .text-stroke {
    opacity: 1;
    fill: none;
    stroke: #fff;
    stroke-width: 2.2px;
    stroke-dashoffset: -900;
    stroke-dasharray: 900;
    stroke-linecap: butt;
    stroke-linejoin: round;
    animation: dash 2s ease-out forwards;
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
}
.ani_box .intro .text-stroke.text-stroke-2 {
    stroke: #009bff;
    animation-delay: 0.8s;
}
.ani_box .intro .text-stroke-3 {
    opacity: 0;
    stroke: #009bff;
    fill: #009bff;
    animation: fill 0.5s ease-out forwards;
    animation-delay: 2.7s;
}
@keyframes dash {
    100% {
        stroke-dashoffset: 0;
    }
}
@keyframes fill {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
#ani_logo #logo-right {
    width: 45px;
    height: 50px;
}
#ani_logo #logo-right image {
    width: 45px;
    height: 56px;
}

/* section 2 */
.about_ctr .ab_content_2 {
    margin-top: 60px;
}
.about_ctr .ab_content_2 img {
    width: 336px;
    margin-top: 60px;
    margin-bottom: 40px;
}
.about_ctr .ab_content_2 p {
    font-size: 14px;
    line-height: 20px;
    color: #333;
    text-align: left;
}

/* section 3 */
.about_ctr .ab_content_w_3 {
    height: 795px;
    background-image: url(../../images/4745@3x.png);
    background-image: -webkit-image-set(
        url(../../images/4745.png) 1x,
        url(../../images/4745@2x.png) 2x,
        url(../../images/4745@3x.png) 3x
    );
    background-image: image-set(
        url(../../images/4745.png) 1x,
        url(../../images/4745@2x.png) 2x,
        url(../../images/4745@3x.png) 3x
    );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}
.about_ctr .ab_content_w_3 .inner .title {
    padding-top: 60px;
    color: #fff;
}
.about_ctr .ab_content_w_3 .inner img {
    width: 336px;
    margin: 60px auto 60px;
}

/* section 4 */
.about_ctr .ab_content_w_4 .bg--yellow2 {
    top: 150px;
}
.about_ctr .ab_content_w_4 .title_content {
    width: 100%;
    margin: 40px auto;
}
.about_ctr .ab_content_w_4 ul {
    overflow: auto;
    position: relative;
    width: calc(100% - 18px);
    height: auto;
    padding: 8px 0 18px 18px;
    white-space: nowrap;
    scroll-behavior: smooth; /* not safari */
}
.about_ctr .ab_content_w_4 ul li {
    display: inline-block;
    width: 124px;
    height: auto;
    margin-top: 10px;
    margin-right: 12px;
    padding: 18px;
    background-color: #fff;
    box-shadow: 2px 2px 20px #00000029;
    vertical-align: top;
    text-align: left;
    white-space: normal;
}
.about_ctr .ab_content_w_4 ul li span {
    display: inline-block;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
    color: #009bff;
}
.about_ctr .ab_content_w_4 ul li h6 {
    height: 70px;
    color: #000;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.48px;
    font-weight: 700;
}
.about_ctr .ab_content_w_4 ul li p {
    height: auto;
    min-height: 72px;
    color: #333;
    font-size: 12px;
    line-height: 18px;
}
/* section 5 */
.about_ctr .ab_bg_content .img_blue_circle_7 {
    display: none;
}
.about_ctr .ab_bg_content .img_yellow_circle_5 {
    display: none;
}
.about_ctr .ab_content_5 .ab_inner {
    width: calc(100% - 24px);
    margin: 0 auto;
}
.about_ctr .ab_history {
    margin-top: 40px;
    padding: 40px 12px 0 20px;
    background: #fff;
    box-shadow: 2px 2px 20px #00000029;
    box-shadow: -2px -2px 20px #00000029;
    text-align: left;
}

.about_ctr .ab_history .ab_year {
    float: left;
    position: relative;
    width: 57px;
    color: #009bff;
    font-size: 16px;
    letter-spacing: -0.4px;
    font-weight: 700;
}
.about_ctr .ab_history .ab_year::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: -4px;
    left: 49px;
    width: 39px;
    height: 18px;
    background: url('../../images/icon-history@3x.png') no-repeat;
    background-size: cover;
}
.about_ctr .ab_history ul {
    display: inline-block;
    width: calc(100% - 91px);
    padding-left: 33px;
    padding-bottom: 40px;
    border-left: 1px solid #dee2e6;
}
.about_ctr .ab_history > div:last-child ul {
    border: none;
}
.about_ctr .ab_history ul span {
    display: inline-block;
    width: 12px;
    margin: 5px 12px;
    font-size: 12px;
    font-weight: 700;
    vertical-align: top;
}
.about_ctr .ab_history li {
    display: inline-block;
    width: calc(100% - 36px);
    padding-bottom: 12px;
    color: #333;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.36px;
}
/* 태블릿용 CSS */
@media all and (min-width: 768px) {
    .about_ctr .ab_content_w_4 ul {
        position: relative;
        overflow: visible;
        width: calc(100% - 24px);
        max-width: 1192px;
        height: auto;
        padding: 0px;
        margin: 120px auto;
        white-space: normal;
    }
    .about_ctr .ab_content_w_4 ul li {
        width: calc((100% - (40px * 3) - (20px * 2)) / 3);
        height: 206px;
        margin: 0px 20px 20px 0px;
        padding: 20px;
        box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.16);
    }
    .about_ctr .ab_content_w_4 ul li:nth-child(3),
    .about_ctr .ab_content_w_4 ul li:nth-child(6) {
        margin-right: 0px;
    }
    .about_ctr .ab_content_w_4 ul li span {
        padding-bottom: 12px;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .about_ctr .ab_content_w_4 ul li h6 {
        height: auto;
        padding-bottom: 20px;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.72px;
    }
    .about_ctr .ab_content_w_4 ul li p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.36px;
    }
}

/* PC용 CSS */
@media all and (min-width: 960px) {
    .about_ctr .content,
    .about_ctr .content_wide {
        margin-top: 200px;
    }
    .about_ctr {
        text-align: left;
    }
    .about_ctr .bg--blue7 {
        display: block;
    }
    .about_ctr .bg--yellow5 {
        display: block;
    }
    
    /* section 1 */
    .about_ctr .ab_content_w_1 {
        margin-top: 0;
        height: 500px;
    }
    .about_ctr .ab_content_w_1 #ani_logo {
        top: 200px;
    }
    #ani_logo #logo-left {
        width: 250px;
        height: 120px;
    }
    #ani_logo #logo-left image {
        width: 250px;
        height: 120px;
    }
    .ani_box .intro {
        height: 120px;
    }
    @keyframes logoslide {
        from {
            width: 0px;
        }
        to {
            width: 270px;
        }
    }
    #ani_logo #logo-right {
        width: 110px;
        height: 120px;
    }
    #ani_logo #logo-right image {
        width: 110px;
        height: 131px;
    }

    /* section 2 */
    .about_ctr .ab_content_2 {
        margin-top: 140px;
    }
    .about_ctr .ab_content_2 .title {
        left: 0;
        padding-top: 42px;
    }
    .about_ctr .ab_content_2 img {
        width: 490px;
        margin-top: 113px;
        margin-bottom: 0;
        margin-right: 65px;
    }
    .about_ctr .ab_content_2 .ab_text {
        display: inline-block;
        width: calc(100% - 555px);
        margin-top: 21%;
        font-size: 20px;
        vertical-align: top;
    }
    .about_ctr .ab_content_2 .ab_text p {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .about_ctr .ab_content_2 p:first-child {
        padding-bottom: 24px;
    }
    /* section 3 */
    .about_ctr .ab_content_w_3 {
        height: 912px;
        background: url(../../images/4700@3x.png);
        background-image: -webkit-image-set(
            url(../../images/4700.webp) 1x,
            url(../../images/4700@2x.webp) 2x,
            url(../../images/4700@3x.webp) 3x
        );
        background-image: image-set(
            url(../../images/4700.webp) 1x,
            url(../../images/4700@2x.webp) 2x,
            url(../../images/4700@3x.webp) 3x
        );
        background-size: cover;
        background-position: bottom;
    }
    .about_ctr .ab_content_w_3 .inner .title {
        padding-top: 80px;
    }
    .about_ctr .ab_content_w_3 .inner img {
        display: inline-block;
        width: 100%;
        margin: 80px auto 0px;
    }

    /* section 4 */
    .about_ctr .ab_content_w_4 .bg--yellow2 {
        top: -200px;
    }
    .about_ctr .ab_content_w_4 h1 {
        display: inline-block;
        width: 360px;
    }
    .about_ctr .ab_content_w_4 .inner > p.title_content {
        vertical-align: middle;
    }
    .about_ctr .ab_content_w_4 .title_content {
        display: inline-block;
        width: calc(100% - 545px);
        margin-left: 15%;
    }
    .about_ctr .ab_content_w_4 ul {
        width: calc(100% - 48px);
    }
    .about_ctr .ab_content_w_4 ul li {
        width: calc((100% - (80px * 3) - (20px * 2)) / 3);
        padding: 40px;
    }
    .about_ctr .ab_content_w_4 ul li p {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }

    /* section 5 */
    .about_ctr .ab_bg_content {
        position: relative;
    }
    .about_ctr .ab_content_5 {
        position: relative;
        max-width: 1792px;
        margin: 0 auto;
    }
    .about_ctr .ab_content_5 .bg--yellow5 {
        bottom: 210px;
        left: 0;
        width: 33.483%;
        height: 32.823%;
    }
    .about_ctr .ab_content_5 .ab_inner {
        position: relative;
        width: calc(100% - 48px);
        max-width: 1196px;
        margin: 0 auto;
        padding: 0 24px;
    }
    .about_ctr .ab_content_5 .ab_circle {
        position: relative;
        max-width: 1372px;
        margin: 0 auto;
    }
    .about_ctr .ab_content_5 .bg--blue7 {
        top: 160px;
        left: 0;
        background-position: right top;
    }
    .about_ctr .ab_history {
        position: relative;
        margin-top: 120px;
        padding: 100px 20px 100px 100px;
    }
    .about_ctr .ab_history .ab_year {
        width: 76px;
        font-size: 32px;
    }
    .about_ctr .ab_history .ab_year::after {
        content: '';
        top: -8px;
        left: 96px;
        width: 74px;
        height: 34px;
        background: url(../../images/icon-history@3x.png) no-repeat;
        background-size: cover;
    }
    .about_ctr .ab_history ul {
        width: calc(100% - 181px);
        padding-bottom: 88px;
        padding-left: 60px;
        margin-left: 36px;
    }
    .about_ctr .ab_history ul span {
        width: 18px;
        margin: 3px 28px;
        font-size: 20px;
    }
    .about_ctr .ab_history li {
        width: calc(100% - 74px);
        padding-bottom: 21px;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.5px;
    }
}
