/* 모바일 css */
.error {
    margin: 100px auto;
    text-align: center;
}
.error .e_tit {
    color: #000;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -.72px;
    font-weight: 700;
}
.error .e_tit span {
    color: #009bff;
}
.error .e_tit_content {
    margin: 20px auto 40px;
    color: #333;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -.42px;
}
.error a {
    display: inline-block;
    width: 98px;
    height: 40px;
    border-radius: 20px;
    background: #343a40;
    color: #fff;
    font-size: 16px;
    line-height: 40px;
    letter-spacing: -.48px;
    font-weight: 700;
}

/* 테블릿용 CSS */
@media all and (min-width:768px) {
}

/* PC용 CSS */
@media all and (min-width:960px) {
    .error {
        margin: 120px auto 160px;
    }
    .error .e_tit {
        font-size: 40px;
        line-height: 60px;
        letter-spacing: -1.2px;
    }
    .error .e_tit_content {
        margin: 20px auto 80px;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -.8px;
    }
    .error a {
        width: 166px;
        height: 60px;
        border-radius: 30px;
        font-size: 24px;
        line-height: 60px;
        letter-spacing: -.72px;
    }
}