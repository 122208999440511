/* title */
.news_cnt .content {
    margin-top: 10px;
}
.news_cnt .content .news_tit {
    padding-top: 30px;
}
.news_cnt .content .news_tit .title_content {
    margin-top: 20px;
}

/* category */
.news_cate {
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: 60px;
    text-align: left;
    white-space: nowrap;
}
.news_cate li {
    display: inline-block;
    width: fit-content;
    height: 40px;
    margin-right: 12px;
    padding: 0 20px;
    border: 1px solid #f1f3f5;
    border-radius: 30px;
    background: #f1f3f5;
    font-size: 16px;
    line-height: 40px;
    letter-spacing: -0.48px;
    color: #666;
}
.news_cate li:hover {
    cursor: pointer;
}
.news_cate li.active {
    background: #343a40;
    color: #fff;
    font-weight: bold;
}

/* content */
.news_ctt .news-group {
    margin-top: 40px;
    margin-left: 0;
    text-align: left;
}

/* pagination */
.news_paginate {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding: 10px;
}
.news_paginate li {
    min-width: 5.8px;
    margin-right: 12px;
    padding: 0 9.1px;
    text-align: center;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.36px;
    color: #666;
}
.news_paginate li:last-of-type {
    padding-right: 0;
}
.news_paginate li:hover {
    cursor: pointer;
}
.news_paginate li.active {
    border-radius: 12px;
    background: #666;
    color: #fff;
    font-weight: 600;
}
.news_paginate li.prev_btn img,
.news_paginate li.next_btn img {
    width: 10px;
    height: 14px;
    vertical-align: sub;
}

/* loading */
.news_loading {
    margin-top: 100px;
    text-align: center;
    font-size: 16px;
}

/* 테블릿용 CSS */
@media all and (min-width: 768px) {
}

/* PC용 CSS */
@media all and (min-width: 960px) {
    /* title */
    .news_cnt .content {
        margin-top: 100px;
    }
    .news_cnt .content .news_tit {
        padding-top: 100px;
    }
    .news_cnt .content .news_tit .title_content {
        margin-top: 40px;
    }

    /* category */
    .news_cate {
        margin-top: 100px;
    }
    .news_cate li {
        height: 60px;
        margin-right: 20px;
        padding: 0 24px;
        font-size: 24px;
        line-height: 60px;
        letter-spacing: -0.72px;
    }

    /* content */
    .news_ctt {
        position: relative;
        left: -24px;
        width: calc(100% + 48px);
    }
    .news_ctt .news-group {
        height: auto;
        margin-top: 80px;
        margin-left: 0;
        padding-left: 24px;
        text-align: center;
        white-space: normal;
    }
    .news_ctt .news-group .news-group-sub {
        /* center align 내 하위 li 의 left align 을 위한 카드 두장의 최소한의 width*/
        width: 810px;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
    }
    .news_ctt .news-group li {
        margin-bottom: 40px;
    }

    /* pagination */
    .news_paginate li {
        min-width: 9px;
        margin-right: 15px;
        padding: 0 7.7px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.48px;
    }
    .news_paginate li.active {
        border-radius: 24px;
        background: #666;
        color: #fff;
        font-weight: 600;
    }
    .news_paginate li.prev_btn img,
    .news_paginate li.next_btn img {
        width: 14px;
        height: 20px;
    }

    /* loading */
    .news_loading {
        margin-top: 200px;
        font-size: 24px;
    }
}

@media all and (min-width: 1236px) {
    .news_ctt .news-group .news-group-sub {
        width: auto;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
    }
}
