/* 모바일용 CSS */
.pro_ctr .bg_blue_circle_7 {
    position: absolute;
    top: 0;
    left: -12px;
    z-index: -1;
    width: 100%;
    height: 80px;
    background-position: left top;
    background-size: 80px 80px;
}
.pro_ctr .pro_top_1 {
    margin-top: 10px;
}
.pro_ctr .pro_top_1_1 {
    position: relative;
    padding-top: 30px;
}
.pro_ctr .pro_top_1 .title_content {
    margin-top: 20px;    
}
.pro_ctr .pro_top_2 figure {
    width: 340px;
    margin: 80px auto 0;
    background-repeat: no-repeat;
    background-position: center top;
    text-align: center;
}
.pro_ctr .pro_top_2 .chair_sec figure {
    height: 405px; /* 310 + 81 + figcaption height */
    background-image: url(../../images/5325@3x.png);
    background-size: 336px 310px;
}
.pro_ctr .pro_top_2 .cushion_sec figure {
    height: 390px;
    background-image: url(../../images/5329@3x.png);
    background-size: 336px 390px;
}
.pro_ctr .pro_top_2 .chair_sec figure img {
    width: 210px;
    height: 315px;
    margin-top: 61px;
}
.pro_ctr .pro_top_2 .cushion_sec figure img {
    width: 280px;
    height: 140px;
    margin-top: 124px;
}
.pro_ctr .pro_top_2 figure figcaption {
    margin-top: 8px;
    color: #666;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.36px;
    text-align: left;
}
.pro_ctr .pro_top_2 figure figcaption::before {
    content: '▲ ';
}
.pro_ctr .pro_top_2 ul.product_sel_list {
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: 40px;
    white-space: nowrap;
    text-align: center;
}
.pro_ctr .pro_top_2 ul.product_sel_list li {
    display: inline-block;
    margin-right: 12px;
    padding: 8px;
    border: 1px solid #f1f3f5;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-origin: content-box;
    cursor: pointer;
}
.pro_ctr .pro_top_2 ul.product_sel_list li div {
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.pro_ctr .pro_top_2 ul.product_sel_list li div.d2a {
    background-image: url(../../images/image-chair-algo-d-2-a-w.png);
    background-image: -webkit-image-set(
        url(../../images/image-chair-algo-d-2-a-w.webp) 1x,
        url(../../images/image-chair-algo-d-2-a-w@2x.webp) 2x,
        url(../../images/image-chair-algo-d-2-a-w@3x.webp) 3x
    );
    background-image: image-set(
        url(../../images/image-chair-algo-d-2-a-w.webp) 1x,
        url(../../images/image-chair-algo-d-2-a-w@2x.webp) 2x,
        url(../../images/image-chair-algo-d-2-a-w@3x.webp) 3x
    );
}
.pro_ctr .pro_top_2 ul.product_sel_list li div.d2 {
    background-image: url(../../images/image-chair-algo-d-2-w.png);
    background-image: -webkit-image-set(
        url(../../images/image-chair-algo-d-2-w.webp) 1x,
        url(../../images/image-chair-algo-d-2-w@2x.webp) 2x,
        url(../../images/image-chair-algo-d-2-w@3x.webp) 3x
    );
    background-image: image-set(
        url(../../images/image-chair-algo-d-2-w.webp) 1x,
        url(../../images/image-chair-algo-d-2-w@2x.webp) 2x,
        url(../../images/image-chair-algo-d-2-w@3x.webp) 3x
    );
}
.pro_ctr .pro_top_2 ul.product_sel_list li div.q7 {
    background-image: url(../../images/image-chair-algo-q-7-w.png);
    background-image: -webkit-image-set(
        url(../../images/image-chair-algo-q-7-w.webp) 1x,
        url(../../images/image-chair-algo-q-7-w@2x.webp) 2x,
        url(../../images/image-chair-algo-q-7-w@3x.webp) 3x
    );
    background-image: image-set(
        url(../../images/image-chair-algo-q-7-w.webp) 1x,
        url(../../images/image-chair-algo-q-7-w@2x.webp) 2x,
        url(../../images/image-chair-algo-q-7-w@3x.webp) 3x
    );
}
.pro_ctr .pro_top_2 ul.product_sel_list li div.lima05 {
    background-image: url(../../images/image-chair-algo-lima-04-l-w.png);
    background-image: -webkit-image-set(
        url(../../images/image-chair-algo-lima-04-l-w.webp) 1x,
        url(../../images/image-chair-algo-lima-04-l-w@2x.webp) 2x,
        url(../../images/image-chair-algo-lima-04-l-w@3x.webp) 3x
    );
    background-image: image-set(
        url(../../images/image-chair-algo-lima-04-l-w.webp) 1x,
        url(../../images/image-chair-algo-lima-04-l-w@2x.webp) 2x,
        url(../../images/image-chair-algo-lima-04-l-w@3x.webp) 3x
    );
}

.pro_ctr .pro_top_2 ul.product_sel_list li div.cushion_b {
    background-image: url(../../images/image-cushion-b-w.png);
    background-image: -webkit-image-set(
        url(../../images/image-cushion-b-w.webp) 1x,
        url(../../images/image-cushion-b-w@2x.webp) 2x,
        url(../../images/image-cushion-b-w@3x.webp) 3x
    );
    background-image: image-set(
        url(../../images/image-cushion-b-w.webp) 1x,
        url(../../images/image-cushion-b-w@2x.webp) 2x,
        url(../../images/image-cushion-b-w@3x.webp) 3x
    );
}
.pro_ctr .pro_top_2 ul.product_sel_list li div.cushion_r {
    background-image: url(../../images/image-cushion-r-w.png);
    background-image: -webkit-image-set(
        url(../../images/image-cushion-r-w.webp) 1x,
        url(../../images/image-cushion-r-w@2x.webp) 2x,
        url(../../images/image-cushion-r-w@3x.webp) 3x
    );
    background-image: image-set(
        url(../../images/image-cushion-r-w.webp) 1x,
        url(../../images/image-cushion-r-w@2x.webp) 2x,
        url(../../images/image-cushion-r-w@3x.webp) 3x
    );
}
.pro_ctr .pro_top_2 ul.product_sel_list li div.cushion_g {
    background-image: url(../../images/image-cushion-g-w.png);
    background-image: -webkit-image-set(
        url(../../images/image-cushion-g-w.webp) 1x,
        url(../../images/image-cushion-g-w@2x.webp) 2x,
        url(../../images/image-cushion-g-w@3x.webp) 3x
    );
    background-image: image-set(
        url(../../images/image-cushion-g-w.webp) 1x,
        url(../../images/image-cushion-g-w@2x.webp) 2x,
        url(../../images/image-cushion-g-w@3x.webp) 3x
    );
}
.pro_ctr .pro_top_2 .chair_sec ul.product_sel_list li {
    width: 57px;
    height: 85px;
}
.pro_ctr .pro_top_2 .cushion_sec ul.product_sel_list li {
    width: 86px;
    height: 44px;
}
.pro_ctr .pro_top_2 ul.product_sel_list li:last-child {
    margin-right: 0px;
}
.pro_ctr .pro_top_2 .product_content {
    margin-top: 60px;
}
.pro_ctr .pro_top_2 .product_content .title_colav {
    color: #009bff;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.48px;
}
.pro_ctr .pro_top_2 .product_content .title {
    margin-top: 4px;
    padding: 0px;
}
.pro_ctr .pro_top_2 .product_content .title_content {
    margin-top: 40px;
    padding: 0px;
}
.pro_ctr .pro_top_2 .product_content .com_icons {
    margin-top: 20px;
}
.pro_ctr .pro_top_2 .product_content .com_icon {
    width: 24px;
    margin-right: 8px;
}
.pro_ctr .pro_top_2 .product_content .app_service_title {
    margin-top: 60px;
    color: #333333;
    line-height: 24px;
    letter-spacing: -0.48px;
    font-weight: 600;
}
.pro_ctr .pro_top_2 .product_content ul {
    margin-top: 20px;
}
.pro_ctr .pro_top_2 .cushion_sec .product_content ul {
    margin-bottom: 100px;
}
.pro_ctr .pro_top_2 .product_content ul li {
    display: inline-block;
    width: calc((100% - 12px) / 2);
    height: 60px;
    margin-right: 12px;
    box-shadow: 2px 2px 10px #00000014;
    text-align: left;
}
.pro_ctr .pro_top_2 .product_content ul li:last-child {
    margin-right: 0px;
}
.pro_ctr .pro_top_2 .product_content ul li img {
    width: 32px;
    margin-top: 14px;
    margin-right: 12.34567901%; /*auto 14px;*/
}
.pro_ctr .pro_top_2 .product_content .app_services .app_icon {
    display: inline-block;
    width: 44%;
    text-align: right;
}
.pro_ctr .pro_top_2 .product_content .app_services .app_content {
    display: inline-block;
    width: 56%;
    margin-top: 11px;
    text-align: left;
    vertical-align: top;
}
.pro_ctr .pro_top_2 .product_content .app_services .title {
    margin-top: 0px;
    color: #333;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.42px;
}
.pro_ctr .pro_top_2 .product_content .app_services .title_content {
    margin-top: 2px;
    color: #666;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: -0.3px;
}
.pro_ctr .pro_top_2 .buy_button {
    display: none;
}
.pro_ctr .floating_buy {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    bottom: 0px;
    width: 100%;
    height: 36px;
    padding: 0px;
    padding-top: 12px;
    background-color: #343a40;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}
.pro_ctr  .floating_buy.disabled {
    background-color: #f1f3f5;
    cursor: not-allowed;
}

.pro_ctr .pro_top_5 {
    align-items: center;
    height: 478px;
    margin-bottom: 0;
    background-color: #f1f3f5;
    text-align: center;
}
.pro_ctr .pro_top_5 ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.pro_ctr .pro_top_5 li {
    width: auto;
    margin-bottom: 12px;
    padding: 20px;
    background-color: #ffffff;
}
.pro_ctr .pro_top_5 li:last-child {
    margin-bottom: 0px;
}
.pro_ctr .pro_top_5 li .li_product_img {
    display: inline-block;
    width: 80px;
    vertical-align: top;
}
.pro_ctr .pro_top_5 li .li_content {
    display: inline-block;
    width: calc(100% - (80px + 3.33333333%));
    margin-left: 3.33333333%; /* 12 / 260*/
}
.pro_ctr .pro_top_5 li .title {
    display: inline-block;
    width: calc(100% - 20px);
    padding: 0px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.48px;
    text-align: left;
}
.pro_ctr .pro_top_5 .com_icon {
    width: 20px;
}
.pro_ctr .pro_top_5 li .title_content {
    width: 100%;
    margin-top: 12px;
    padding: 0px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.36px;
    text-align: left;
}
.pro_ctr .pro_top_5 li .li_model {
    margin-top: 8px;
    color: #666;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: -0.3px;
    text-align: left;
}
.pro_ctr .pro_top_5 .show_more a {
    color: #009bff;
}
.pro_ctr .pro_top_5 .show_more .arrow-r {
    border-color: #009bff;
}
.pro_ctr .s_qna {
    background: linear-gradient(119deg, #006edc, #00b1ff);
}
.pro_ctr .s_qna button {
    color: #009bff;
}

/* 테블릿용 CSS */
@media all and (min-width: 768px) {
}

/* PC용 CSS */
@media all and (min-width: 960px) {
    .pro_ctr .bg_blue_circle_7 {
        left: -24px;
        height: 180px;
        background-size: 180px 180px;
    }
    .pro_ctr .pro_top_1 {
        margin-top: 100px;
    }
    .pro_ctr .pro_top_1_1 {
        padding-top: 100px;
    }
    .pro_ctr .pro_top_1 .title_content {
        margin-top: 40px;
    }
    .pro_ctr .pro_top_2 .flex_sec {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .pro_ctr .pro_top_2 figure {
        display: inline-block;
        order: 1;
        width: 49.16107383%;
        max-width: 586px;
        margin-top: 120px;
    }
    .pro_ctr .pro_top_2 .chair_sec figure {
        width: none;
        height: 680px;
        background-position: top;
        background-size: contain;
    }
    .pro_ctr .pro_top_2 .cushion_sec figure {
        height: 680px;
        background-position: top;
        background-size: contain;
    }
    .pro_ctr .pro_top_2 .chair_sec figure img {
        width: 380px;
        height: 560px;
        margin-top: 72px;
    }
    .pro_ctr .pro_top_2 .cushion_sec figure img {
        width: 400px;
        height: 176px;
        margin: 248px 41px 0px 61px;
    }
    .pro_ctr .pro_top_2 figure figcaption {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.48px;
    }
    .pro_ctr .pro_top_2 ul.product_sel_list {
        order: 3;
        width: 51%;
        height: 187px;
        text-align: left;
    }
    .pro_ctr .pro_top_2 ul.product_sel_list li {
        margin-right: 20px;
        padding: 20px 15px;
        border-width: 2px;
    }
    .pro_ctr .pro_top_2 .chair_sec ul.product_sel_list li {
        width: calc(25% - 53px);
        max-width: 95.5px;
        height: 76%;
    }
    .pro_ctr .pro_top_2 .cushion_sec ul.product_sel_list li {
        width: calc(33.333333% - 53px);
        max-width: 149px;
        height: 76%;
    }
    .pro_ctr .pro_top_2 .product_content {
        display: inline-block;
        order: 2;
        width: calc(100% - 49.16107383% - 20px);
        margin-top: 120px;
        margin-left: 20px;
    }
    .pro_ctr .pro_top_2 .product_content .title_colav {
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.72px;
    }
    .pro_ctr .pro_top_2 .product_content .title {
        font-size: 42px;
        line-height: 64px;
        letter-spacing: -1.26px;
    }
    .pro_ctr .pro_top_2 .product_content .title_content {
        width: 100%;
        margin-top: 80px;
    }
    .pro_ctr .pro_top_2 .product_content .com_icon {
        width: 30px;
        margin-right: 12px;
    }
    .pro_ctr .pro_top_2 .product_content .app_service_title {
        margin-top: 72px;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.72px;
    }
    .pro_ctr .pro_top_2 .cushion_sec .product_content ul {
        margin-bottom: 0px;
    }
    .pro_ctr .pro_top_2 .product_content ul li {
        width: calc((100% - 20px) / 2);
        height: 108px;
        margin-right: 20px;
        box-shadow: 3px 3px 20px #00000014;
    }
    .pro_ctr .pro_top_2 .product_content ul li img {
        width: 60px;
        margin-top: 24px;
        margin-right: 19%; /*auto 14px;*/
    }
    .pro_ctr .pro_top_2 .product_content .app_services .app_content {
        margin-top: 22px;
    }
    .pro_ctr .pro_top_2 .product_content .app_services .title {
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.72px;
    }
    .pro_ctr .pro_top_2 .product_content .app_services .title_content {
        margin-top: 4px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.48px;
    }
    .pro_ctr .pro_top_2 .buy_button {
        display: block;
        order: 4;
        width: 49%;
        margin-top: 40px;
        border-top: 2px solid #f1f3f5;
        text-align: right;
    }
    .pro_ctr .pro_top_2 .buy_button button {
        width: 234px;
        height: 60px;
        margin-top: 39px;
        margin-left: 16px;
    }

    .pro_ctr .floating_buy {
        display: none;
    }

    .pro_ctr .pro_top_5 {
        height: 525px;
    }
    .pro_ctr .pro_top_5 ul {
        flex-direction: row;
        align-items: center;
    }
    .pro_ctr .pro_top_5 li {
        display: inline-block;
        width: calc((100% - 20px - 128px) / 2);
        height: auto;
        padding: 32px;
        margin-right: 20px;
        margin-bottom: 0px;
    }
    .pro_ctr .pro_top_5 li:last-child {
        margin-right: 0px;
    }
    .pro_ctr .pro_top_5 li .li_product_img {
        width: 140px;
    }
    .pro_ctr .pro_top_5 li .li_content {
        display: inline-block;
        width: calc(100% - (140px + 5.747126437%));
        margin-left: 5.747126437%; /* 30 / 522*/
    }
    .pro_ctr .pro_top_5 li .title {
        width: calc(100% - 30px);
        padding: 0;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.72px;
    }
    .pro_ctr .pro_top_5 .com_icon {
        width: 30px;
    }
    .pro_ctr .pro_top_5 li .title_content {
        margin-top: 20px;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .pro_ctr .pro_top_5 li .li_model {
        margin-top: 20px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.48px;
    }
    .pro_ctr .pro_top_5 .show_more a {
        margin-top: 36px;
    }

    .pro_ctr .pro_top_6 {
        height: 331px;
        margin: 0;
    }
    .pro_ctr .pro_top_6 p {
        padding: 80px 0px 40px;
        font-size: 48px;
        line-height: 64px;
        letter-spacing: -1.44px;
    }
    .pro_ctr .pro_top_6 button {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.72px;
    }
}
