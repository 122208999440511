.scroll_btn {
    position: fixed;
    bottom: 4px;
    right: 4px;
    height: 36px;
    cursor: pointer;
}
.scroll_btn.on_product {
    bottom: 48px;
}
/* 테블릿용 CSS */
@media all and (min-width:768px) {
}

/* PC용 CSS */
@media all and (min-width:960px) {
    .scroll_btn {
        bottom: 16px;
        right: 30px;
        height: 60px;
    }
    .scroll_btn.on_product {
        bottom: 10px;
    }
}