/* 모바일용 CSS */
.pol_ctr {
    word-break: break-all;
}
.pol_ctr .pol_top_1 {
    margin-top: 10px;
}
.pol_ctr .pol_top_1 .pol_tit {
    padding-top: 40px;
}
.pol_ctr .pol_top_1 .title_content {
    margin-top: 20px;
}
.pol_ctr .content i {
    color: #888888;
    font-size: 14px;
    font-style: italic;
}
/* section 1 */
.pol_content_1 .pol_tab_menu li {
    position: relative;
    top: 1px;
    width: calc((100% - 4px) / 3);
    height: 36px;
    margin-right: 0;
    padding: 10px 0;
    border-radius: 8px 8px 0 0;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #dee2e6;
    background: #f1f3f5;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.42px;
}
.pol_content_1 .pol_tab_menu li.active {
    border: 1px solid #dee2e6;
    border-bottom: none;
    background: #fff;
    color: #000;
    font-weight: 600;
}
/* tab 1 */
.pol_content_1 {
    color: #333;
}
.pol_content_1 .pol_tit {
    padding-top: 60px;
    color: #000;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.6px;
    font-weight: 600;
}
.pol_content_1 .pol_sub_tit {
    padding: 40px 0 20px;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.4px;
}
.pol_content_1 .title_content {
    padding-bottom: 12px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.42px;
}
.pol_content_1 .pol_unord_ul > li,
.pol_content_1 .pol_ord_ul > li {
    padding-bottom: 12px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.42px;
}
.pol_content_1 .pol_ord_ul > li {
    padding-left: 20px;
}
.pol_content_1 .pol_unord_ul .pol_ord_ul li {
    padding-top: 12px;
    padding-bottom: 0;
}
.pol_content_1 .pol_unord_ul span,
.pol_content_1 .pol_ord_ul span,
.pol_content_1 table span {
    padding: 0 5px;
    font-size: 17px;
    font-weight: 900;
}
/* tab 2 */
.pol_tab_2 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.42px;
}
.pol_tab_2 table {
    margin-bottom: 12px;
    color: #000;
}
.pol_tab_2 table th {
    height: 36px;
    border: 1px solid #707070;
    background: #343a40;
    color: #fff;
    font-weight: 600;
}
.pol_tab_2 table th,
.pol_tab_2 table td {
    vertical-align: middle;
}
.pol_tab_2 table td {
    padding: 8px 4px;
    border: 1px solid #707070;
    text-align: center;
}
.pol_tab_2 .required_tbl th:first-child {
    width: 29.9%;
}
.pol_tab_2 .required_tbl th:nth-child(2) {
    width: 46%;
}
.pol_tab_2 .required_tbl th:nth-child(3) {
    width: 22.1%;
}
.pol_tab_2 .required_tbl tr td:nth-of-type(2),
.pol_tab_2 .selected_tbl tr td:nth-of-type(2) {
    text-align: left;
}
.pol_tab_2 .selected_tbl th {
    width: calc(600px / 3);
}
.pol_tab_2 .offered_tbl {
    margin-top: 12px;
}
.pol_tab_2 .offered_tbl th {
    width: calc(600px / 4);
    min-width: 84px;
}
.pol_tab_2 .offered_tbl td {
    padding: 16px;
}
/* 테블릿용 CSS */
@media all and (min-width: 768px) {
}

/* PC용 CSS */
@media all and (min-width: 960px) {
    .pol_ctr .pol_top_1 {
        margin-top: 100px;
    }
    .pol_ctr .pol_top_1 .pol_tit {
        padding-top: 100px;
    }
    .pol_ctr .pol_top_1 .title_content {
        /* max-width: 975px; */
        margin-top: 40px;
    }
    .pol_content_1 {
        margin-top: 100px;
    }
    .pol_content_1 .pol_tab_menu {
        border-bottom: 1px solid #dee2e6;
    }
    .pol_content_1 .pol_tab_menu li {
        width: 214px;
        padding: 20px 0;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.5px;
    }
    .pol_content_1 .pol_tab_menu li.active {
        border-bottom: 1px solid #fff;
    }
    /* tab 1 */
    .pol_content_1 .pol_tit {
        padding-top: 80px;
        font-size: 32px;
        line-height: 48px;
        letter-spacing: -0.96px;
    }
    .pol_content_1 .pol_sub_tit {
        padding: 60px 0 40px;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.72px;
    }
    .pol_content_1 .title_content {
        margin-top: 0;
        color: #333;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .pol_content_1 .pol_unord_ul > li,
    .pol_content_1 .pol_ord_ul > li {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .pol_content_1 .pol_ord_ul > li {
        padding-left: 40px;
    }
    .pol_content_1 .pol_unord_ul span,
    .pol_content_1 .pol_ord_ul span,
    .pol_content_1 table span {
        padding: 0 5px;
        font-size: 24px;
        font-weight: 900;
    }
    /* tab 2 */
    .pol_tab_2 {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .pol_tab_2 table {
        margin-bottom: 20px;
    }
    .pol_tab_2 table th {
        height: 53px;
    }
    .pol_tab_2 table td {
        padding: 20px;
    }
    .pol_tab_2 .required_tbl th:first-child {
        width: 28.3%;
    }
    .pol_tab_2 .required_tbl th:nth-child(2) {
        width: 50.4%;
    }
    .pol_tab_2 .required_tbl th:nth-child(3) {
        width: 21.3%;
    }
    .pol_tab_2 .selected_tbl th {
        width: calc(1240px / 3);
    }
    .pol_tab_2 .offered_tbl {
        margin-top: 20px;
    }
    .pol_tab_2 .offered_tbl th {
        width: calc(1240px / 3);
    }
    .pol_tab_2 .offered_tbl td {
        padding: 32px;
    }
}
