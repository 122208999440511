/* 모바일용 CSS */
.ctt_ctr .ctt_content {
    margin-top: 10px;
}
.ctt_ctr .ctt_content .ctt_tit {
    padding-top: 30px;
}
.ctt_ctr .ctt_content .ctt_tit .title_content {
    margin-top: 20px;
}
/* form */
.ctt_ctr .ctt_form {
    margin-top: 60px;
}
.ctt_ctr .ctt_form .form label,
.ctt_ctr .ctt_form .form input,
.ctt_ctr .ctt_form .form textarea {
    display: block;
    width: 100%;
    font-size: 12px;
}
.ctt_ctr .ctt_form .form label {
    margin-bottom: 6px;
    color: #333;
    font-weight: 500;
}
.ctt_ctr .ctt_form .form label span {
    color: #009bff;
}
.ctt_ctr .ctt_form .form input,
.ctt_ctr .ctt_form .form textarea{
    min-height: 32px;
    margin-bottom: 16px;
    padding: 0;
    border: 1px solid #868e96;
}
.ctt_ctr .ctt_form .ctt_submit {
    text-align: right;
}
.ctt_ctr .ctt_form .ctt_submit button{
    background: #009bff;
}
/* map */
.ctt_ctr .ctt_form .ctt_map {
    margin-top: 40px;
}
.ctt_ctr .ctt_form .ctt_map iframe{
    margin-bottom: 8px;
}
.ctt_ctr .ctt_form .ctt_map p{
    font-size: 12px;
    line-height: 18px;
}
/* 테블릿용 CSS */
@media all and (min-width:768px) {
}

/* PC용 CSS */
@media all and (min-width:960px) {
    .ctt_ctr .ctt_content {
        margin-top: 100px;
    }
    .ctt_ctr .ctt_content .ctt_tit {
        padding-top: 100px;
    }
    .ctt_ctr .ctt_content .ctt_tit .title_content {
        margin-top: 40px;
    }
    /* form */
    .ctt_ctr .ctt_form {
        margin-top: 120px;
    }
    .ctt_ctr .ctt_form form {
        order: 2;
        width: 44%;
        margin-left: 8.5%;
    }
    .ctt_ctr .ctt_form .form label,
    .ctt_ctr .ctt_form .form input,
    .ctt_ctr .ctt_form .form textarea {
        font-size: 16px;
    }
    .ctt_ctr .ctt_form .form input {
        height: 48px;
        margin-bottom: 20px;
    }
    .ctt_ctr .ctt_form .form textarea {
        margin-bottom: 40px;
    }
    /* map */
    .ctt_ctr .ctt_form {
        display: flex;
    }
    .ctt_ctr .ctt_form .ctt_map {
        order: 1;
        width: 56%;
        margin: 0;
    }
    .ctt_ctr .ctt_form .ctt_map iframe {
        height: 474px;
    }
    .ctt_ctr .content .ctt_map p{
        font-size: 16px;
        line-height: 24px;
    }
}