/* 모바일용 CSS */

/* 기본 스타일 */
.header {
    position: fixed;
    top: 0;
    z-index: 99999;
    width: 100%;
    height: 48px;
    background: rgba(256, 256, 256, 0.8);
    backdrop-filter: blur(10px);
}
.header:hover {
    background: rgba(256, 256, 256, 1);
    backdrop-filter: none;
}
.header .inner {
    max-width: 100%;
}
.header .logo {
    float: left;
}
.header .logo a {
    display: inline-block;
    height: 48px;
    line-height: 58px;
}
.header .logo a img {
    height: 24px;
}
.header .menu {
    position: relative;
    top: 0;
    bottom: 0;
    z-index: 1000;
    -webkit-transition: right 0.3s;
    -moz-transition: right 0.3s;
    -ms-transition: right 0.3s;
    -o-transition: right 0.3s;
    transition: right 0.3s;
    background-color: #fff;
}
.header .menu.on {
    right: 0;
}
.header .dimmed {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    height: 100vh;
    background: #000;
    opacity: 0.8;
}
.header .dimmed--none {
    display: none;
}
.header .gnb.open {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    overflow: scroll;
    background-color: #fff;
}
.header .gnb.close {
    display: none;
}
.header .menu-toggle-btn,
.header .menu-toggle-btn span {
    display: inline-block;
    box-sizing: border-box;
    transition: all 0.4s;
}
.header .menu-toggle-btn {
    float: right;
    position: absolute;
    top: 15px;
    right: 0;
    width: 24px;
    height: 18px;
    cursor: pointer;
}
.header .menu-toggle-btn span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: #333;
}
.header .menu-toggle-btn span:nth-of-type(1) {
    top: 0;
}
.header .menu-toggle-btn span:nth-of-type(2) {
    top: 8px;
}
.header .menu-toggle-btn span:nth-of-type(3) {
    bottom: 0;
}
.header .menu-toggle-btn.active-1 span:nth-of-type(1) {
    -webkit-transform: translateY (10px) rotate (-45deg);
    transform: translateY(8px) rotate(-45deg);
}
.header .menu-toggle-btn.active-1 span:nth-of-type(2) {
    opacity: 0;
}
.header .menu-toggle-btn.active-1 span:nth-of-type(3) {
    -webkit-transform: translateY(-10px) rotate(45deg);
    transform: translateY(-8px) rotate(45deg);
}

/* menu list */
.header .gnb .m_list {
    order: 2;
}
.header .gnb li {
    color: #333;
    font-size: 16px;
    vertical-align: middle;
    text-decoration: none;
    cursor: pointer;
}
.header .gnb .m_list > li {
    display: block;
    padding: 20px 0 20px 10%;
    font-weight: 600;
}
.header .gnb .m_list li.active {
    color: #009bff !important;
    font-weight: 500 !important;
}
.header .gnb .m_list .m_sub {
    display: none;
}
.header .gnb .m_list .m_sub.m_open {
    display: block;
    position: relative;
}
.header .gnb .m_list .m_sub.m_open li {
    padding: 16px 0 16px 0;
    font-weight: 400;
}
.header .gnb .m_list .m_sub.m_open li:first-child {
    padding-top: 32px;
}

/* lang */
.header .gnb .m_lang {
    order: 1;
    margin-bottom: 32px;
    margin-right: 54px;
    color: #adb5bd;
    font-size: 12px;
    text-align: right;
}
.header .gnb .m_lang li {
    display: inline-block;
    padding: 16px 8px;
    color: #adb5bd;
    font-size: 12px;
    vertical-align: initial;
    cursor: pointer;
}
.header .gnb .m_lang li.active {
    color: #000000;
    font-weight: bold;
}
.header .gnb .m_lang li:last-child {
    padding-left: 0;
}
.header .gnb .m_lang .l_eng::before {
    content: '|';
    padding-right: 8px;
    color: #adb5bd;
    font-size: 12px;
    font-weight: normal;
}

/* 테블릿용 CSS */
@media all and (min-width: 768px) {
}

/* PC용 CSS */
@media all and (min-width: 960px) {
    .header {
        width: 100%;
        height: 60px;
        margin: 0 auto;
    }
    .header .inner {
        max-width: 1192px;
        height: 60px;
    }
    .header .logo a {
        height: 60px;
        line-height: 90px;
    }
    .header .logo a img {
        height: 42px;
    }
    /* menu list */
    .header .menu-toggle-btn {
        display: none;
    }
    .header .menu {
        float: right;
        position: static;
        width: calc(100% - 200px);
        height: 60px;
        background: none;
    }
    .header .gnb {
        display: block !important;
        float: right;
        position: static;
        height: 100%;
    }
    .header .gnb .m_list {
        height: 100%;
    }
    .header .gnb > ul > li {
        float: left;
    }
    .header .gnb .m_list {
        display: inline-block;
        position: relative;
    }
    .header .gnb .m_list > li {
        height: 100%;
        margin-right: 64px;
        padding: 0;
        line-height: 60px;
        text-align: center;
        font-weight: 400;
    }
    .header .gnb .m_list .m_service {
        position: relative;
    }
    .header .gnb .m_list .m_sub.m_open {
        display: none;
    }
    .header .gnb .m_list .m_sub.sub_service.w_open {
        display: none;
        position: absolute;
        top: 60px;
        left: -38px;
        min-width: 140px;
        background: #fff;
        box-shadow: 0px 8px 16px 0px rgba(0.2, 0.2, 0.2, 0.2);
    }
    .header .gnb .m_list .m_sub.sub_contact.w_open {
        display: none;
        position: absolute;
        top: 60px;
        left: -30px;
        min-width: 140px;
        background: #fff;
        box-shadow: 0px 8px 16px 0px rgba(0.2, 0.2, 0.2, 0.2);
    }
    .header .gnb .m_list .m_sub.w_open li {
        display: block;
        padding: 0 0 0 16px;
        color: black;
        line-height: 40px;
        text-align: left;
        font-weight: 400;
        text-decoration: none;
    }
    .header .gnb .m_list .m_sub.w_open li:hover {
        background: #efefef;
    }
    .header .gnb .m_list .m_service:hover .m_sub.w_open {
        display: block;
    }
    /* lang */
    .header .gnb .m_lang {
        display: inline-block;
        height: 100%;
        margin: 0;
    }
    .header .gnb .m_lang li {
        padding: 0;
        font-size: 14px;
        line-height: 60px;
    }
    .header .gnb .m_lang .l_eng::before {
        padding: 0 12px;
        font-size: 14px;
    }
}
