/* 모바일용 CSS */
.rec_ctr .rec_top_1{
    margin-top: 10px;
}
.rec_ctr .rec_top_1 .rec_top_1_1 {
    padding-top: 30px;
}
.rec_ctr .rec_top_1 .rec_top_1_1 .title_content {
    margin-top: 20px;
}
.rec_ctr .rec_top_2 .rec_sec {
    margin-top: 80px;
    text-align: center;
}
.rec_ctr .rec_top_2 .rec_sec .title_content {
    margin-top: 20px;
}
.rec_ctr .rec_top_2 .rec_sec button.rec_home_btn {
    margin-top: 40px;
}

.rec_ctr .re_list {
    padding: 39.5px 0px 19.5px;
    border-bottom: 1px solid #f1f3f5;
    text-align: left;
}
.rec_ctr .re_list:first-child{
    padding-top: 0px;;
}
.rec_ctr .re_title {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.6px;
    font-weight: bold;
}
.rec_ctr .re_type {
    margin-top: 8px;
    color: #009bff;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.42px;
}
.rec_ctr .re_keyword{
    margin-top: 16px;
}
.rec_ctr ul.re_keyword li{
    display:inline-block;
    width: fit-content;
    height: 17px;
    margin-right: 8px;
    padding: 4px 8px ;
    border: 1px solid #DEE2E6;
    color: #666666;
    font: normal normal normal 12px/18px 'Manrope';
    letter-spacing: -0.36px;
    line-height: 18px;
    text-align: center;
}
.rec_ctr ul.re_keyword li:last-child{
    margin-right: 0px;
}
.rec_ctr .re_apply{
    text-align: right;
}
.rec_ctr .re_list button{
    margin-top: 20px;
    background-color: #009BFF;
}

.rec_ctr .welfare_sec{
    margin-top: 80px;
    vertical-align: middle;
}
.rec_ctr .rect_box {
    display: inline-block;
    width: 100%;
    margin-bottom: 12px;
    background: #fff 0% 0% no-repeat padding-box;
    vertical-align: middle;
    box-shadow: 2px 2px 10px #00000029;
}
.rec_ctr .rect_box .welfare_img {
    display: inline-block;
    width: 42.8%;
    margin: 12px auto;
    text-align: center;
    vertical-align: middle;
}
.rec_ctr .rect_box .welfare_content {
    display: inline-block;
    width: 57.2%;
    margin: 12px auto;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.48px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
}
.rec_ctr .rect_box img {
    height: 118px;
}

/* 테블릿용 CSS */
@media all and (min-width:768px) {
    .rec_ctr .rect_box {
        width: calc((100% - 14px)/2);
        margin-right: 14px;
        margin-bottom: 15px;
    }
    .rec_ctr .rect_box:nth-child(2),
    .rec_ctr .rect_box:nth-child(4),
    .rec_ctr .rect_box:nth-child(6){
        margin-right: 0px;
    }
}

/* PC용 CSS */
@media all and (min-width:960px) {
    .rec_ctr .rec_top_1{
        margin-top: 100px;
    }
    .rec_ctr .rec_top_1 .rec_top_1_1 {
        padding-top: 100px;
    }
    .rec_ctr .rec_top_1 .rec_top_1_1 .title_content {
        margin-top: 40px;
    }
    .rec_ctr .rec_top_2 .rec_sec {
        margin-top: 120px;
    }
    .rec_ctr .rec_top_2 .rec_sec .title_content {
        margin-top: 20px;
    }
    .rec_ctr .rec_top_2 .rec_sec button.rec_home_btn {
        margin-top: 80px;
    }
    .rec_ctr .re_list {
        padding: 79px 0px 39px;
        border-bottom-width: 2px;
    }
    .rec_ctr .re_title {
        font-size: 32px;
        line-height: 48px;
        letter-spacing: -0.96px;
    }
    .rec_ctr .re_type {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .rec_ctr .re_keyword{
        margin-top: 40px;
    }
    .rec_ctr ul.re_keyword li{
        height: 27px;
        padding: 8px 16px;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .rec_ctr .re_apply{
        float: right;
        position: relative;
        top: -80px;
    }
    .rec_ctr .welfare_sec{
        margin-top: 120px;
    }
    .rec_ctr .rect_box {
        width: calc((100% - 40px)/3);
        height:442px;
        margin-right:20px;
        margin-bottom: 20px;
        box-shadow: 5px 5px 13px #00000029;
    }
    .rec_ctr .rect_box:nth-child(2),
    .rec_ctr .rect_box:nth-child(4){
        margin-right:20px;
    }
    .rec_ctr .rect_box:nth-child(3),
    .rec_ctr .rect_box:nth-child(6){
        margin-right: 0px;
    }
    .rec_ctr .rect_box .welfare_img {
        display: block;
        width: 100%;
        margin: 40px auto 0;
    }
    .rec_ctr .rect_box .welfare_content {
        display: block;
        width: 100%;
        margin-top: 32px;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.72px;
    }
    .rec_ctr .rect_box img {
        height: 260px;
    }

}