/* common */
.service {
    text-align: center;
}

/* section 1 */

/* s1 - health */
.s_health .s_content_1 {
    margin-top: 40px;
    margin-bottom: 80px;
}
.s_health .s_content_1 .title_content {
    margin-top: 40px;
}
.down-btn > a {
    display: inline-block;
    width: fit-content;
    padding: 0 20px;
    border: 1px solid #666;
    border-radius: 0;
    background: #fff;
    color: #666;
    font-size: 14px;
    line-height: 38px;
    letter-spacing: -0.42px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
}
/* s1 - study */
.s_study .s_content_1 {
    margin-top: 40px;
    margin-bottom: 80px;
}
.s_study .s_content_1 .title_content {
    margin-top: 40px;
}
.s_study .s_comingsoon {
    display: block;
    padding-top: 8px;
    color: #ffc107;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.48px;
}
.down-btn-dis button {
    width: fit-content;
    padding: 0 20px;
    border-radius: 0;
    background: #f1f3f5;
    color: #666;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.42px;
    font-weight: 600;
    cursor: auto;
}
/* .s_study .s_content_1 .down-btn .s_later {
    display: block;
    padding-top: 4px;
    color: #666;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: -0.3px;
} */
/* s1 - work */
.s_content_1 .title img {
    display: block;
    width: 110px;
    height: 32px;
    margin: 0 auto;
    margin-bottom: 8px;
}
.s_content_1 .title {
    width: 100%;
    min-width: 155px;
    margin: 0 auto;
    padding-top: 8px;
}
.s_work .s_content_1 .down-btn-dis button {
    border: 1px solid #666;
    background: #fff;
    cursor: pointer;
}
.s_work .s_content_1 .down-btn-dis button .arrow-r {
    margin-bottom: 2px;
}

/* banner */
.content_wide.s_banner_wrap {
    height: 94px;
    background: #ffc107;
    color: #000;
    text-align: left;
}
.s_banner {
    display: flex;
    width: calc(100% - 24px);
    max-width: 600px;
    height: 94px;
    margin: 0 auto;
}
.s_banner .s_banner_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 144px);
}
.s_banner .s_banner_sub {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.36px;
}
.s_banner .s_banner_main {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.48px;
    font-weight: 600;
}
.s_banner .s_banner_btn {
    display: flex;
    align-items: center;
}
.s_banner .s_banner_btn button {
    margin-top: 6px;
    width: 144px;
}

/* section 2 */
.s_content_2 {
    margin: 100px auto;
}
.s_content_2 .title_content {
    width: auto;
    margin-top: 40px;
    margin-bottom: 60px;
}
.s_content_2 .rect_box {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    margin-bottom: 12px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 2px 10px #00000029;
}
.s_content_2 .rect_box img {
    display: inline-block;
    width: 120px;
    height: 118px;
    margin: 12px auto;
    text-align: center;
    vertical-align: middle;
}
.s_content_2 .rect_box p {
    display: inline-block;
    width: 57.2%;
    margin: 12px auto;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.48px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
}
.s_content_2 .rect_box span {
    color: #666;
    font-size: 10px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.3px;
}
.s_content_2 .rect_box .d_block {
    display: block;
}
.s_content_2 .rect_box .d_inline-b {
    display: inline-block;
}

/* section img area */
.s_main_img {
    padding: 25px 0 45px;
}
.s_img_bottom,
.s_img_left {
    margin: 40px 0;
}
.s_content_3,
.s_content_4,
.s_content_5,
.s_content_6,
.s_content_7 {
    margin-bottom: 100px;
}

/* section 3, section 4 */

/* s3 - study */
.s_tab_wrap .s_tabmenu {
    margin-bottom: 100px;
}
.s_tab_wrap .s_tabmenu li {
    display: inline-block;
    width: calc((100% - 12px) / 2);
    padding: 20px 0;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #dee2e6;
    background: #f1f3f5;
    color: #666;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.42px;
    cursor: pointer;
}
.s_tab_wrap .s_tabmenu li.active {
    border: 1px solid #dee2e6;
    border-bottom: none;
    background: #fff;
    color: #214eee;
    font-weight: 600;
}
.s_study .s_group .s_content_3 img {
    width: 320px;
    height: 210px;
}
.s_study .s_content_3 .title span,
.s_study .s_content_7 .title span {
    display: block;
    margin-bottom: 4px;
    color: #ffc107;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.48px;
}
.s_study ul.tab li.active {
    border-bottom-color: #214eee;
}
.s_content_3 .tab li,
.s_content_4 .tab li {
    font-size: 14px;
}
.s_content_3 img,
.s_content_4 img {
    width: 330px;
    height: 551px;
}
.s_content_3 .h_tab_text,
.s_content_4 .h_tab_text {
    margin: 0 13px;
}
.s_content_3 .h_tab_text .title_content,
.s_content_4 .h_tab_text .title_content {
    margin: 0 auto;
    letter-spacing: -0.42px;
    line-height: 20px;
}
.s_content_3 .h_tab_text .title_content:first-of-type,
.s_content_4 .h_tab_text .title_content:first-of-type,
.s_content_5 .h_tab_text .title_content:first-of-type,
.s_content_6 .h_tab_text .title_content:first-of-type {
    margin-top: 20px;
    margin-bottom: 20px;
}
.s_content_7 img {
    width: 320px;
    height: 210px;
}

/* health - review */
.content_wide.h_review_wrap {
    position: relative;
    z-index: -1;
    margin-bottom: 0;
    background: #f1f3f5;
}
.h_review_wrap .inner {
    padding-top: 60px;
}
.h_review_wrap .inner .bg--blue7 {
    display: none;
}
.h_review_wrap .title_content {
    width: calc(100% - 94px);
    margin: 40px auto;
}
.h_review_wrap ul.h_review {
    overflow: auto;
    position: relative;
    height: auto;
    padding-top: 20px;
    padding-bottom: 80px;
    text-align: left;
    white-space: nowrap;
}
.h_review_wrap .h_review li {
    display: inline-block;
    width: 180px;
    height: auto;
    margin-right: 12px;
    padding: 20px 20px;
    background: #fff;
    vertical-align: top;
    box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, 0.16);
}
.h_review_wrap .h_review li:first-of-type {
    margin-left: 12px;
}
.h_review_wrap .h_review .h_star-rating {
    width: 107px;
}
.h_review_wrap .h_review .h_star-rating,
.h_review_wrap .h_review .h_star-rating span {
    display: inline-block;
    overflow: hidden;
    height: 17px;
    background: url(../../images/5361.png) no-repeat;
}
.h_review_wrap .h_review .h_star-rating span {
    width: 100%;
    background-position: left bottom;
    line-height: 0;
    vertical-align: top;
}
.h_review_wrap .h_review li p {
    height: auto;
    min-height: 135px;
    margin-top: 16px;
    color: #000;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.42px;
    white-space: normal;
}
.h_review_wrap .h_review li .h_review_info {
    color: #666;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.36px;
}
.h_review_wrap .h_review li .h_review_month {
    float: right;
    color: #009bff;
    font-family: Noto Sans CJK KR;
    font-size: 12px;
}

.h_review_wrap .inner .title span {
    display: inline-block;
    position: relative;
    top: 5px;
    animation: bounce 0.3s ease infinite alternate;
}
.h_review_wrap .inner .title span:nth-child(2) {
    animation-delay: 0.1s;
}
.h_review_wrap .inner .title span:nth-child(3) {
    animation-delay: 0.2s;
}
.h_review_wrap .inner .title span:nth-child(4) {
    animation-delay: 0.3s;
}
.h_review_wrap .inner .title span:nth-child(5) {
    animation-delay: 0.4s;
}
.h_review_wrap .inner .title span:nth-child(6) {
    animation-delay: 0.5s;
}
.h_review_wrap .inner .title span:nth-child(7) {
    animation-delay: 0.6s;
}
.h_review_wrap .inner .title span:nth-child(8) {
    animation-delay: 0.7s;
}
.h_review_wrap .inner .title span:nth-child(9) {
    animation-delay: 0.8s;
}
.h_review_wrap .inner .title span:nth-child(10) {
    animation-delay: 0.9s;
}
.h_review_wrap .inner .title span:nth-child(11) {
    animation-delay: 1s;
}
.h_review_wrap .inner .title span:nth-child(12) {
    animation-delay: 1.1s;
}
.h_review_wrap .inner .title span:nth-child(13) {
    animation-delay: 1.2s;
}

@keyframes bounce {
    100% {
        top: 0px;
        /* text-shadow: 0 1px 0 #ddd, 0 2px 0 #ddd, 0 3px 0 #ddd, 0 4px 0 #ddd,
            0 5px 0 #ddd,
        0 30px 20px rgba(100, 100, 100, .2); */
    }
}

/* service qna - health */
.s_qna {
    display: table;
    height: 200px;
    margin: 0;
    background: linear-gradient(118deg, #009bff, #91d9ff 99%);
    text-align: center;
}
.s_qna_content {
    display: table-cell;
    margin: 0 auto;
    color: #fff;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.72px;
    vertical-align: middle;
}
.s_qna .s_qna_content p {
    padding-bottom: 24px;
}
.s_qna button {
    background: #fff;
    color: #009bff;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.48px;
}
/* service qna - study */
.s_study .s_qna {
    background: linear-gradient(119deg, #214eee, #a6b8f8);
}
.s_study .s_qna button {
    color: #214eee;
}
/* service qna - work */
.s_work .s_qna {
    background: linear-gradient(119deg, #2464c0, #a7c1e6);
}
.s_work .s_qna button {
    color: #2464c0;
}

/* 테블릿용 CSS */
@media all and (min-width: 768px) {
}

/* PC용 CSS */
@media all and (min-width: 960px) {
    .service {
        text-align: left;
    }
    /* section 1 */
    /* s1 - health */
    .s_health .s_content_1 {
        position: relative;
        height: 600px;
        margin: 120px auto 133px !important;
    }
    .s_content_1 .h_inner_1 {
        width: 500px;
        padding-top: 1px;
    }
    .s_content_1 .title {
        margin: 0;
    }
    .s_content_1 .title img {
        width: 100%;
        max-width: 190px;
        height: 58px;
        margin: 60px 0 16px 0;
    }
    .s_health .s_content_1 .title_content {
        width: 100%;
        margin-bottom: 60px;
    }
    .s_main_img {
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100% - 520px);
    }
    .s_main_img img {
        width: 100%;
        height: 100%;
    }
    .down-btn > a {
        width: 229px;
        height: 64px;
        padding: 0;
        font-size: 20px;
        line-height: 64px;
        letter-spacing: -0.6px;
    }
    .down-btn ul.d_open {
        margin-top: 12px;
        background-color: #ffffff;
        box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.16);
    }
    .down-btn ul.d_open li {
        height: 55px;
        font-size: 16px;
        line-height: 55px;
        letter-spacing: -0.48px;
        font-weight: 400;
    }
    .down-btn ul.d_open li:first-child {
        background: url(../../images/icon-playstore@3x.png) no-repeat;
        background-position: 24px;
        background-size: 20px;
    }
    .down-btn ul.d_open li:last-child {
        background: url(../../images/icon-apple@3x.png) no-repeat left;
        background-position: 24px;
        background-size: 20px;
    }
    .down-btn ul.d_open li:hover {
        background-color: #efefef;
    }
    .down-btn button img {
        padding: 10px 20px 10px 24px;
        height: 20px;
    }
    .down-btn ul.d_close {
        display: none;
    }
    /* s1 - study */
    .s_study .s_content_1 {
        position: relative;
        height: 600px;
        margin: 120px auto 133px !important;
    }
    .s_study .s_content_1 .title_content {
        width: 100%;
        max-width: 487px;
        margin-bottom: 60px;
    }
    .s_study .s_comingsoon {
        padding-top: 16px;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.72px;
    }
    .down-btn-dis button {
        width: 229px;
        height: 60px;
        padding: 0;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    /* .s_study .s_content_1 .down-btn .s_later {
        padding-top: 8px;
        font-size: 14px;
        line-height: 20px;
    } */
    /* s1 - work */
    .s_work .s_content_1 {
        margin: 120px auto 133px !important;
        height: 600px;
    }
    /* banner 2 */
    .content_wide.s_banner_wrap {
        margin-top: 0;
        height: 167px;
    }
    .s_banner {
        max-width: 1052px;
        height: 87px;
        margin: 0 auto;
        padding: 40px 0;
    }
    .s_banner .s_banner_text {
        width: calc(100% - 234px);
    }
    .s_banner .s_banner_sub {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .s_banner .s_banner_main {
        height: 54px;
        font-size: 36px;
        line-height: 48px;
        letter-spacing: -1.08px;
    }
    .s_banner .s_banner_btn button {
        width: 234px;
        margin-top: 27px;
    }

    /* section 2 */
    .s_content_2 {
        margin: 200px auto;
    }
    .s_content_2 .title_content,
    .s_content_2 .title_content {
        margin-bottom: 120px;
    }
    .s_content_2 .rect_box {
        width: calc((100% - 40px) / 3);
        height: 442px;
        margin-right: 20px;
        margin-bottom: 20px;
        box-shadow: 5px 5px 13px #00000029;
    }
    .s_content_2 .rect_box:nth-of-type(1),
    .s_content_2 .rect_box:nth-of-type(2) {
        margin-right: 20px;
    }
    .s_content_2 .rect_box:nth-of-type(3) {
        margin-right: 0px;
    }
    .s_content_2 .rect_box img {
        display: block;
        width: 264px;
        height: 260px;
        margin: 40px auto 0;
    }
    .s_content_2 .rect_box p {
        display: block;
        width: 90%;
        min-width: 220px;
        margin: 32px auto 12px;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.72px;
    }
    .s_content_2 .rect_box span {
        font-size: 16px;
        letter-spacing: -0.48px;
    }

     /* section img area */
    .s_img_bottom,
    .s_img_left {
        margin: 0;
    }
    .h_img_left,
    .s_img_left {
        position: absolute;
        top: 0;
        left: -20px;
    }
    .h_img_right {
        position: absolute;
        top: 0;
        right: 0;
    }
    .s_img_bottom {
        display: inline-block;
    }

    .s_content_3, 
    .s_content_4,
    .s_content_5,
    .s_content_6 {
        position: relative;
        height: 722px;
        margin-bottom: 200px;
    }
    .s_content_7 {
        margin-bottom: 200px;
    }

    /* section 3 / section 4 */
    .s_content_3 img,
    .s_content_4 img {
        width: 430px;
        height: 742px;
    }
    /* s3 - health */
    .s_health .s_content_3 .title,
    .s_health .s_content_4 .title {
        position: relative;
        padding-top: 105px;
    }
    /* s3 - study  */
    .s_tab_wrap .s_tabmenu {
        margin-bottom: 200px;
        border-bottom: 1px solid #dee2e6;
    }
    .s_tab_wrap .s_tabmenu li {
        position: relative;
        top: 1px;
        width: 214px;
        padding: 20px 0;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.5px;
        text-align: center;
    }
    .s_tab_wrap .s_tabmenu li.active {
        border: 1px solid #dee2e6;
        border-bottom: 1px solid #fff;
        background: #fff;
        color: #214eee;
        font-weight: 600;
    }
    .s_study .s_content_3 .title span,
    .s_study .s_content_7 .title span {
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.72px;
    }
    .s_study .s_content_3 .title,
    .s_study .s_content_4 .title {
        padding-top: 105px;
    }
    .s_study .s_group .s_content_3 {
        height: 610px;
    }
    .s_study .s_group .s_content_3 img {
        width: 400px;
        height: 608px;
    }
    .s_study .s_group .s_content_3 .title {
        padding-top: 20px;
    }
    .s_content_3 .h_inner_3 {
        width: 48.147%;
        height: 100%;
        margin-left: 51.853%;
    }
    .s_content_4 .h_inner_4 {
        width: 47.147%;
        height: 100%;
        margin-right: 52.853%;
    }
    .s_content_3 .tab,
    .s_content_4 .tab,
    .s_study .s_content_3.s_tabs3 .tab,
    .s_study .s_content_3.s_tabs4 .tab {
        margin-top: 80px;
        margin-bottom: 64px;
    }
    .s_content_3 .tab li,
    .s_content_4 .tab li {
        height: 30px;
        margin-right: 11.79%;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .s_content_3 .h_tab_text,
    .s_content_4 .h_tab_text {
        margin: 0;
    }
    .s_content_3 .h_tab_text .title_content,
    .s_content_4 .h_tab_text .title_content {
        width: auto;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .s_content_3 .h_tab_text .title_content:first-of-type,
    .s_content_4 .h_tab_text .title_content:first-of-type {
        margin-top: 64px;
        margin-bottom: 36px;
    }
    /* section 5 */
    .s_content_5 .h_tab_text .title_content,
    .s_content_6 .h_tab_text .title_content {
        width: auto;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .s_content_5 .h_tab_text .title_content:first-of-type,
    .s_content_6 .h_tab_text .title_content:first-of-type {
        margin-top: 64px;
        margin-bottom: 36px;
    }
    .s_content_5 {
        height: 742px;
    }
    .s_content_5 .s_inner_5 {
        width: 56.096%;
        height: 100%;
        margin-right: 52.853%;
    }
    .s_content_5 .s_inner_5 .title {
        margin-bottom: 100px;
        padding-top: 105px;
    }

    /* section 6 */
    .s_content_6 {
        height: 742px;
    }
    .s_content_6 .s_inner_6 {
        width: 42.147%;
        height: 100%;
        margin-left: 51.853%;
    }
    .s_content_6 .s_inner_6 .title {
        margin-bottom: 100px;
        padding-top: 105px;
    }
    /* section 7 */
    .s_content_7 .title {
        width: 101%;
        margin-bottom: 120px;
    }
    .s_content_7 .s_img_bottom {
        width: calc(100% - 379px);
    }
    .s_content_7 .s_group_text {
        display: inline-block;
        width: 279px;
        margin-left: 100px;
        vertical-align: bottom;
    }
    .s_content_7 .s_group_text .title_content {
        position: relative;
        top: -70px;
        width: 279px;
        margin-top: 0;
    }
    .s_content_7 img {
        width: 100%;
        height: 100%;
    }

    /* health review */
    .h_review_wrap .inner {
        position: relative;
        padding-top: 180px;
    }
    .h_review_wrap .inner .bg--blue7 {
        display: block;
        top: 80px;
    }
    .h_review_wrap .title_content {
        margin: 40px 0 100px;
    }
    .h_review_wrap ul.h_review {
        margin-left: max(1px, calc(50% - 620px));
        margin-right: 0px;
        padding-left: 24px;
        padding-bottom: 200px;
    }
    .h_review_wrap .h_review li {
        width: 245px;
        margin-right: 20px;
        padding: 32px 19px;
        box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.16);
    }
    .h_review_wrap .h_review li:first-of-type {
        margin-left: 0;
    }
    .h_review_wrap .h_review .h_star-rating {
        width: 133px;
    }
    .h_review_wrap .h_review .h_star-rating,
    .h_review_wrap .h_review .h_star-rating span {
        display: inline-block;
        overflow: hidden;
        height: 21px;
        background: url(../../images/5360.png) no-repeat;
    }
    .h_review_wrap .h_review .h_star-rating span {
        width: 100%;
        background-position: left bottom;
        line-height: 0;
        vertical-align: top;
    }
    .h_review_wrap .h_review li p {
        min-height: 230px;
        margin-top: 24px;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
        word-break: break-all;
    }
    .h_review_wrap .h_review li .h_review_info {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.48px;
    }
    .h_review_wrap .h_review li .h_review_month {
        font-size: 16px;
    }
    .h_review_wrap .inner .title span {
        top: 0;
        text-shadow: 0 18px 10px rgba(100, 100, 100, 0.4);
    }
    @keyframes bounce {
        100% {
            top: -10px;
            text-shadow: 0 30px 20px rgba(100, 100, 100, 0.2);
        }
    }

    /* section qna */
    .s_qna {
        height: 331px;
    }
    .s_qna .s_qna_content p {
        padding-bottom: 40px;
        font-size: 48px;
        line-height: 64px;
        letter-spacing: -1.44px;
    }
    .s_qna button {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.72px;
    }
}
