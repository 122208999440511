/* 모바일용 CSS */
/* top */
.footer{
    background: #343a40;
    color: #fff;
}
.footer .f_menulist .f_menu{
    margin: 0 auto;
    padding: 19px 12px;
    border-bottom: 1px solid #343a40;
    background: #474d52;
    font-size: 12px;
    text-align: left;
    font-weight: 600;
    cursor: pointer;
}
.footer .f_menulist .f_menu.f_close ul {
    display: none;
}
.footer .f_menulist .f_menu.f_open ul {
    margin-top: 24px;
}
.footer .f_menulist .f_menu.f_open ul li {
    display: inline-block;
    margin-right: 20px;
    color: #fff;
    font-weight: 400;
}
.footer .f_menulist .f_menu.f_open ul li a{
    text-decoration: none;
}
.footer .f_menulist .f_menu .f_arrow-b {
    float: right;
    margin-right: 12px;
    padding: 3px;
    border: solid #fff;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.footer .f_menulist .f_menu .f_arrow-t {
    float: right;
    padding: 3px;
    margin-right: 12px;
    border: solid #fff;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
}

/* bottom */
.footer .f_help {
    padding: 18px 12px 0 12px;
}
.footer .f_logo {
    display: inline-block;
    margin-bottom: 36px;
}
.footer .f_logo img {
    height: 24px;
}
.footer .f_logo p {
    padding: 8px 0;
    font-size: 12px;
}
.footer .f_sns {
    float: right;
}
.footer .f_sns img{
    height: 24px;
}
.footer .f_sns img{
    margin-left: 8px;
}
.footer .f_info {
    padding: 0 12px 18px 12px;
    font-size: 12px;
    line-height: 1.5;
}
.footer .f_info ul {
    padding-bottom: 16px;
    border-bottom: .5px solid #d8d8d8;
}
.footer .f_info .f_copyright {
    padding-top: 16px;
}

/* 테블릿용 CSS */
@media all and (min-width:768px) {
}

/* PC용 CSS */
@media all and (min-width:960px) {
    .footer .f_inner {
        max-width: 1240px;
        margin: 0 auto;
    }
    .footer .f_menulist {
        float: right;
    }
    .footer .f_area {
        padding: 60px 12px 12px 12px;
    }
    .footer .f_help {
        display: inline-block;
        padding: 0;
    }
    .footer .f_logo {
        margin-bottom: 72px;
    }
    .footer .f_logo img {
        height: 42px;
    }
    .footer .f_logo p {
        padding: 18px 0;
        font-size: 16px;
    }
    .footer .f_sns {
        float: none;
    }
    .footer .f_sns img{
        height: 36px;
        margin-right: 8px;
        margin-bottom: 12px;
    }
    .footer .f_menulist .f_menu {
        display: inline-block;
        margin: 0 24px;
        padding: 0;
        background: none;
        vertical-align: top;
        cursor: auto;
    }
    .footer .f_menulist .f_menu:last-child {
        margin-right: 0;
    }
    .footer .f_menulist .f_menu strong {
        font-size: 16px;
    }
    .footer .f_menulist .f_menu ul{
        display: block !important;
        padding-top: 40px;
        color: #fff;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
    .footer .f_menulist .f_menu ul li a{
        text-decoration: none;
    }
    .footer .f_menulist .f_menu.f_open ul {
        margin: 0;
    }
    .footer .f_menulist .f_menu.f_open ul li {
        display: block;
        margin: 0;
    }
    .footer .f_menulist .f_menu.f_open ul li a{
        font-weight: 400;
    }
    .footer .f_menulist .f_menu .f_arrow-b {
        display: none;
    }
    .footer .f_menulist .f_menu .f_arrow-t {
        display: none;
    }
    .footer .f_info {
        padding: 24px 12px 32px 12px;
        border-top: .5px solid #d8d8d8;
    }
    .footer .f_info ul {
        border: 0;
    }
    .footer .f_info ul li {
        float: left;
    }
    .footer .f_info ul li::after {
        content:"|";
        padding: 0 8px;
        font-size: 10px;
        vertical-align: top;
    }
    .footer .f_info ul li:last-of-type::after{
        content: "";
    }
    .footer .f_info .f_copyright {
        padding-top: 8px;
    }
}
