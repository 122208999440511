/* common */

/* font */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 100;
    font-display: fallback; 
    src: local('Noto Sans CJK KR'),
        /* url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansCJKKR-Thin.woff2)
            format('woff2'), */
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansCJKKR-Thin.woff)
            format('woff'),
        url(../../fonts/NotoSansCJKkr-Thin.otf) format('opentype');
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 300;
    font-display: fallback; 
    src: local('Noto Sans CJK KR'),
        /* url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansCJKKR-Light.woff2)
            format('woff2'), */
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff)
            format('woff'),
        url(../../fonts/NotoSansCJKkr-Light.otf) format('opentype');
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 400;
    font-display: fallback; 
    src: local('Noto Sans CJK KR'),
        /* url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansCJKKR-Regular.woff2)
            format('woff2'), */
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff)
            format('woff'),
        url(../../fonts/NotoSansCJKkr-Regular.otf) format('opentype');
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 500;
    font-display: fallback; 
    src: local('Noto Sans CJK KR'),
        /* url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansCJKKR-Medium.woff2)
            format('woff2'), */
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff)
            format('woff'),
        url(../../fonts/NotoSansCJKkr-Medium.otf) format('opentype');
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 700;
    font-display: fallback; 
    src: local('Noto Sans CJK KR'),
        /* url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansCJKKR-Bold.woff2)
            format('woff2'), */
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff)
            format('woff'),
        url(../../fonts/NotoSansCJKkr-Bold.otf) format('opentype');
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 900;
    font-display: fallback; 
    src: local('Noto Sans CJK KR'),
        /* url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansCJKKR-Black.woff2)
            format('woff2'), */
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff)
            format('woff'),
        url(../../fonts/NotoSansCJKkr-Black.otf) format('opentype');
}
.lang_ko_font,
.lang_ko_font button {
    font-family: 'Noto Sans CJK KR' !important;
}
.lang_en_font,
.lang_en_font button {
    font-family: 'Manrope' !important;
}

/* TODO : b tag 상속으로 underline opt */
a {
    color: inherit;
    text-decoration: none;
}
b {
    font-weight: bold !important;
}
button:disabled {
    background-color: #f1f3f5 !important;
    cursor: not-allowed;
} 
.form input.invalid {
    border-color: red !important;
    color: red !important;
}
.no_display {
    display: none;
}

/* 모바일용 CSS */

/* layout */
.wrap {
    width: 100%;
    min-width: 360px;
    margin: 0px auto;
    padding-top: 48px;
    font-size: 16px; /* base font size */
    word-break: keep-all;
}
.container {
    margin: 0px auto;
}
.content {
    width: 100%;
    max-width: 624px;
    margin: 0 auto 100px;
}
.content_wide {
    width: 100%;
    margin: 0 auto 100px;
}
.inner {
    width: calc(100% - 24px);
    max-width: 600px;
    margin: 0 auto;
}

/* background graphic - 마지막 숫자는 꼭지점 방향 */
.bg_wrap {
    position: relative;
}
.light {
    opacity: .4;
}
.bg--yellow2 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 180px;
    background: url(../../images/bg-graphic-2@3x.png) no-repeat right top;
    background-size: 180px 180px;
}
.bg--yellow5 {
    position: absolute;
    background: url(../../images/bg-graphic-3@3x.png) no-repeat left top;
    background-size: 600px 600px;
}
.bg--yellow7 {
    position: absolute;
    top: 0;
    left: -12px;
    z-index: -1;
    width: 100%;
    height: 80px;
    background: url(../../images/bg-graphic-7@3x.png) no-repeat left top ;
    background-size: 80px 80px;
}
.bg--blue5 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 500px;
    background: url(../../images/bg-graphic-1@3x.png) no-repeat left top;
    background-size: 480px 480px;
}
.bg--blue7{
    position: absolute;
    top: 0;
    left: -12px;
    z-index: -1;
    width: 100%;
    height: 80px;
    background: url(../../images/bg-graphic-4@3x.png) no-repeat left top;
    background-size: 80px 80px;
}

/* title set */
.title {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.72px;
    font-weight: bold;
}
.title_content {
    color: #333;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.42px;
}
.title .up {
    display: block;
    color: #ffc107;
    font-size: 16px;
}

/* tab menu */
/* TODO : 추후에 li가 n개 일 때도 사용가능하게 만들 것 */
.tab {
    margin-top: 60px;
}
/* li - underline style */
.tab li {
    display: inline-block;
    width: fit-content;
    height: 30px;
    margin-right: 6.5%;
    color: #666;
    font-size: 16px;
    line-height: 36px;
    letter-spacing: -0.48px;
    text-align: center;
    cursor: pointer;
}
.tab.tab_under li.active {
    border-bottom: 2px solid #009bff;
    color: #000;
    font-weight: 700;
}
/* li - border style */
.tab.tab_btn li {
    border: 1px solid #dee2e6;
    width: calc((100% - 16px) / 2);
    height: auto;
    margin-right: 12px;
}
.tab.tab_btn li.active {
    border: 1px solid #666;
    color: #000;
    font-weight: 700;
}
.tab li:last-child {
    margin-right: 0px !important;
}
button {
    width: auto;
    min-width: 98px;
    height: 40px;
    border: none;
    border-radius: 30px;
    background: #343a40;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.48px;
    opacity: 1;
    cursor: pointer;
}

/* title underline effect */
em {
    display: inline-block;
    height: 34px;
    background-color: transparent;
    text-decoration: none;
}
.main_line {
    height: auto;
    font-weight: 600;
    box-shadow: inset 0 -6px 0 rgba(0, 155, 255, 0.16); /*#009bff;*/
}
.health_line {
    box-shadow: inset 0 -8px 0 rgba(0, 155, 255, 0.16) /*#009bff;*/;
}
.study_line {
    box-shadow: inset 0 -8px 0 rgba(33, 78, 238, 0.16);/* #214eee;*/
}
.work_line {
    box-shadow: inset 0 -8px 0 rgba(36, 100, 192, 0.16); /*#2464c0;*/
}
.health_color {
    color: #009bff;
}
.study_color {
    color: #214eee;
}
.work_color {
    color: #2464c0;
}

/* 더 알아보기 text */
.show_more a {
    display: inline-block;
    width: calc(100% - 12px);
    margin-top: 20px;
    color: #666;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.36px;
    text-align: right;
}

/* arrow */
.arrow-t {
    float: right;
    margin-top: 7px;
    margin-right: 13%;
    padding: 4px;
    border: solid #666;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}
.arrow-b {
    float: right;
    margin-right: 13%;
    padding: 4px;
    border: solid #666;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.arrow-r {
    display: inline-block;
    margin: 0 0 1px 5px;
    padding: 2.5px;
    border: solid #666;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/* display */
.u_mobile {
    display: inline;
}
.u_tablet {
    display: inline;
}
.u_desk {
    display: none;
}
.scroll-none {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.scroll-none::-webkit-scrollbar {
    display: none;
}

/* news list */
.news-group {
    overflow-x: scroll;
    overflow-y: hidden;
    height: 340px;
    margin: 60px 0px 0px 12px;
    text-align: center;
    white-space: nowrap;
}
.news-group li {
    display: inline-block; /*Display inline and maintain block characteristics.*/
    height: 324px;
    width: 240px;
    margin-right: 12px;
    box-shadow: 2px 2px 10px #00000029;
    text-align: left;
    vertical-align: top; /*Makes sure all the divs are correctly aligned.*/
    white-space: normal; /*Prevents child elements from inheriting nowrap.*/
    cursor: pointer;
}
.news-group li:hover {
    box-shadow: 2px 2px 10px #00000062;
    -webkit-transform: scale(1.012);
    -moz-transform: scale(1.012);
    -o-transform: scale(1.012);
    transform: scale(1.012);
    transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1) 0s, clip-path 0.5s;
}
.news-group .news_img {
    overflow: hidden;
    width: 240px;
    height: 140px;
}
.news-group .news_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.news-group .news_txt_cnt {
    padding: 20px 24px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.36px;
}
.news-group .news_txt_cnt .news_type {
    color: #009bff;
}
.news-group .news_txt_cnt .news_title {
    display: -webkit-box;
    overflow: hidden;
    height: 40px;
    margin-top: 4px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.42px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: bold;
}
.news-group .news_txt_cnt .news_title_content {
    display: -webkit-box;
    overflow: hidden;
    height: 36px;
    margin-top: 12px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.news-group .news_txt_cnt .news_date {
    margin-top: 21px;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: -0.3px;
}


/* 테블릿용 CSS */
@media all and (min-width: 768px) {
    .u_tablet {
        display: none;
    }
}

/* PC용 CSS */
@media all and (min-width: 960px) {
    /* layout */
    .wrap {
        padding-top: 60px;
    }
    .content {
        width: 100%;
        max-width: 1240px;
        margin: 0 auto 200px;
    }
    .content_wide {
        margin-bottom: 200px;
    }
    .content_wide.max_width,
    .content_wide .max_width {
        max-width: 1960px;
    }
    .inner {
        width: calc(100% - 48px);
        max-width: 1192px;
    }

    /* background */
    .bg--yellow7 {
        left: -24px;
        height: 180px;
        background-size: 180px 180px;
    }
    .bg--yellow2 {
        width: 600px;
        max-width: 1960px;
        height: 600px;
        background-size: 600px 600px;
    }
    .bg--blue7 {
        left: -24px;
        height: 180px;
        background-size: 180px 180px;
    }

    /* title set */
    .title {
        font-size: 48px;
        line-height: 64px;
        letter-spacing: -1.44px;
    }
    .title_content {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .title .up {
        font-size: 24px;
    }

    .tab {
        margin-top: 100px;
    }
    /* li - underline style */
    .tab li {
        margin-right: 60px;
        font-size: 24px;
        line-height: 64px;
        letter-spacing: -0.72px;
    }
    .tab.tab_under li.active {
        border-bottom-width: 4px;
    }
    /* li - border style */
    .tab.tab_btn li {
        width: 228px;
        margin-right: 20px;
        border-width: 2px;
    }
    .tab.tab_btn li.active {
        border: 2px solid #666;
    }
    .tab li:last-child {
        margin-left: 0px !important;
    }
    button {
        min-width: 166px;
        height: 60px;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.72px;
    }

    /* title underline effect */
    em {
        height: 60px;
    }
    .main_line {
        box-shadow: inset 0 -12px 0 rgba(0, 155, 255, 0.16); /*#009bff;*/
    }
    .health_line {
        box-shadow: inset 0 -16px 0 rgba(0, 155, 255, 0.16) /*#009bff;*/;
    }
    .study_line {
        box-shadow: inset 0 -16px 0 rgba(33, 78, 238, 0.16);
    }
    .work_line {
        box-shadow: inset 0 -16px 0 rgba(36, 100, 192, 0.16); /*#2464c0;*/
    }

    /* 더 알아보기 text */
    .show_more a {
        margin-top: 60px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.48px;
    }

    /* arrow */
    .arrow-b,
    .arrow-t {
        display: none;
    }
    .service .arrow-t,
    .service .arrow-b {
        display: inline-block;
        padding: 5px;
        border-width: 0 2px 2px 0;
    }
    .service .arrow-t {
        margin-top: 28px;
    }
    .service .arrow-b {
        margin-top: 21px;
    }
    .arrow-r {
        padding: 4px;
        border-width: 0 2px 2px 0;
    }

    /* display */
    .u_mobile {
        display: none;
    }
    .u_desk {
        display: inline;
    }

    /* news */
    .news-group {
        height: 610px;
        margin-top: 100px;
        padding-top: 22px;
    }
    .news-group li {
        width: 384px;
        height: 574px;
        margin-right: 20px;
        box-shadow: 5px 5px 30px #00000029;
    }
    .news-group .news_img {
        width: 384px;
        height: 240px;
    }
    .news-group .news_txt_cnt {
        padding: 40px;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .news-group .news_txt_cnt .news_title {
        height: 72px;
        margin-top: 12px;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.72px;
        font-weight: bold;
    }
    .news-group .news_txt_cnt .news_title_content {
        height: 61px;
        margin-top: 12px;
    }
    .news-group .news_txt_cnt .news_date {
        margin-top: 40px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.48px;
    }
}
