.confirm_dlg .dimmed {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100000;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.4);
}
.confirm_dlg .dimmed-none {
    display: none;
}
.confirm_dlg .dlg_flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100vh;
}
.confirm_dlg .dlg_box {
    width: 300px;
    margin: auto;
    padding: 40px 18px;
    background-color: white;
    text-align: center !important; 
    opacity: 1;
}
.confirm_dlg .title {
    padding: 0px !important;
    text-align: center !important; 
}
.confirm_dlg .title_content {
    margin: 20px 0px 40px !important;
    text-align: center !important; 
}
.confirm_dlg .title_content .bold_blue {
    color: #009bff;
    font-weight: bold;
}

/* 테블릿용 CSS */
@media all and (min-width:768px) {
}

/* PC용 CSS */
@media all and (min-width:960px) {
    .confirm_dlg .dlg_box {
        width: 440px;
        padding: 60px 80px;
    }
    .confirm_dlg .title {
        font-size: 40px;
        line-height: 60px;
        letter-spacing: -1.2px;
    }
    .confirm_dlg .title_content {
        width: auto;
        margin: 20px 0px 80px !important;
    }
}