.main_ctr .title,
.main_ctr .title_content {
    text-align: center;
}

/* sec 1 */
.main_ctr .main_top_1 {
    margin-top: 40px;
    margin-bottom: 80px;
}
.main_ctr .main_top_1 p.title_content {
    margin: 30px 32px 60px;
}
.main_ctr .main_top_1_1 {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
.main_ctr .main_top_1_1 .bg--yellow7 {
    top: -20px;
    height: 100px;
    background-size: 100px 100px;
}
.main_ctr .main_top_1_2 .content_img {
    text-align: center;
}
.main_ctr .main_top_1_2 .content_img img {
    width: 83.3333%;
    max-width: 460px;
}

/* sec 2 */
.main_ctr .main_top_2_1 .title {
    padding-top: 20px;
    color: #009bff;
}
.main_ctr .main_top_2_1 .title .black {
    color: #000000;
}
.main_ctr .main_top_2_1 .title_content {
    margin: 40px 15px 60px;
}
.main_ctr .main_top_2_2 ul li {
    height: 128px;
    padding-left: 12px;
    background-repeat: no-repeat;
    background-size: cover;
}
.main_ctr .main_top_2_2_1 {
    background-image: url(../../images/image-main-1-m@3x.png);
    background-image: -webkit-image-set(
        url(../../images/image-main-1-m.webp) 1x,
        url(../../images/image-main-1-m@2x.webp) 2x,
        url(../../images/image-main-1-m@3x.webp) 3x
    );
    background-image: image-set(
        url(../../images/image-main-1-m.webp) 1x,
        url(../../images/image-main-1-m@2x.webp) 2x,
        url(../../images/image-main-1-m@3x.webp) 3x
    );
}
.main_ctr .main_top_2_2_2 {
    background-image: url(../../images/image-main-2-m@3x.png);
    background-image: -webkit-image-set(
        url(../../images/image-main-2-m.webp) 1x,
        url(../../images/image-main-2-m@2x.webp) 2x,
        url(../../images/image-main-2-m@3x.webp) 3x
    );
    background-image: image-set(
        url(../../images/image-main-2-m.webp) 1x,
        url(../../images/image-main-2-m@2x.webp) 2x,
        url(../../images/image-main-2-m@3x.webp) 3x
    );
}
.main_ctr .main_top_2_2_3 {
    background-image: url(../../images/image-main-3-m@3x.png);
    background-image: -webkit-image-set(
        url(../../images/image-main-3-m.webp) 1x,
        url(../../images/image-main-3-m@2x.webp) 2x,
        url(../../images/image-main-3-m@3x.webp) 3x
    );
    background-image: image-set(
        url(../../images/image-main-3-m.webp) 1x,
        url(../../images/image-main-3-m@2x.webp) 2x,
        url(../../images/image-main-3-m@3x.webp) 3x
    );
}
.main_ctr .main_top_2_2 .title {
    padding-top: 57px;
    color: #ffffff;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.6px;
    text-align: left;
}
.main_ctr .main_top_2_2 .title_content {
    text-align: left;
    color: #ffffff;
    margin: 0px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.42px;
}

/* sec 3 */
.main_ctr .main_top_3 .tab {
    margin-bottom: 40px;
    text-align: center;
}
.main_ctr .main_top_3 .service_sec .service_img {
    width: 89.28571429%;
    max-width: 580px;
    margin: 0px auto;
}
.main_ctr .main_top_3 .service_sec .service_img img {
    width: 100%;
}
.main_ctr .main_top_3 .service_sec .service_content {
    margin-top: 40px;
}
.main_ctr .main_top_3 .service_sec .service_content .title {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.6px;
    text-align: left;
}
.main_ctr .main_top_3 .service_sec .service_content .title img {
    width: 24px;
}
.main_ctr .main_top_3 .service_sec .service_content .title img,
.main_ctr .main_top_3 .service_sec .service_content .title span:first-of-type {
    margin-right: 8px;
    vertical-align: middle;
}
.main_ctr .main_top_3 .service_sec .service_content .title .comingsoon {
    font-family: 'Manrope';
    color: #ffc107;
}
.main_ctr .main_top_3 .service_sec .service_content .title_content {
    margin-top: 20px;
    text-align: left;
}
.main_ctr .main_top_3 .service_sec .service_content ul {
    margin: 40px 0px 0px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.42px;
    font-weight: bold;
}
.main_ctr .main_top_3 .service_sec .service_content ul li {
    margin-bottom: 8px;
}
.main_ctr .main_top_3 .service_sec .service_content ul li span {
    display: inline-block;
}
.main_ctr .main_top_3 .service_sec .service_content ul li span:first-child {
    width: 16px;
    margin-right: 10px;
    color: #dee2e6;
    vertical-align: top;
}
.main_ctr .main_top_3 .service_sec .service_content ul li span:nth-child(2) {
    width: calc(100% - 26px);
}

/* sec 4 */
.main_ctr .main_top_4 {
    height: 116px;
}
.main_ctr .main_top_4 .bg_wide_rect {
    position: relative;
    width: 100%;
    height: 94px;
    background-color: #ffc107;
}
.main_ctr .main_top_4 .bg_wide_rect.u_mobile {
    cursor: pointer;
}
.main_ctr .main_top_4 .main_top_4_content {
    position: relative;
    max-width: 600px;
    margin: 0px auto;
    cursor: pointer;
}
.main_ctr .main_top_4 .main_top_4_left {
    display: inline-block;
    width: calc(100% - 185px);
    height: 94px;
    margin-left: 24px;
    text-align: left;
    vertical-align: top;
}
.main_ctr .main_top_4 .main_top_4_left .main_top_4_flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 94px;
}
.main_ctr .main_top_4 .main_top_4_content .title_content {
    width: 100%;
    margin: 0 0 8px 0;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.36px;
    text-align: left;
}
.main_ctr .main_top_4 .main_top_4_content .title {
    padding: 0px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.48px;
    text-align: left;
}
.main_ctr .main_top_4 .main_top_4_right {
    display: inline-block;
    position: absolute;
    top: -20px;
    width: 149px;
}
.main_ctr .main_top_4 .main_top_4_right img {
    width: 149px;
}
.main_ctr .main_top_4 .show_more a {
    display: none;
}

/* sec 5 */
.main_ctr .main_top_5 .title {
    padding: 0px;
    text-align: center;
}
.main_ctr .main_top_5 ul {
    margin-top: 60px;
}
.main_ctr .main_top_5 ul li {
    display: inline-block;
    width: calc((100% - 36px) / 4);
    margin-right: 12px;
    margin-bottom: 12px;
    text-align: center;
}
.main_ctr .main_top_5 ul li:nth-child(4),
.main_ctr .main_top_5 ul li:last-child {
    margin-right: 0px;
}
.main_ctr .main_top_5 ul li img {
    width: 100%;
    max-width: 180px;
}

/* sec 6 */
.main_ctr .main_top_6 .title {
    padding: 0px;
    text-align: center;
}

/* sec 7 */
.main_ctr .main_top_7_1 p.title_content {
    margin: 40px 0 60px;
}
.main_ctr .bg--blue5 {
    display: none;
}
.main_ctr .ctt_form .form label,
.main_ctr .ctt_form .form input,
.main_ctr .ctt_form .form textarea {
    display: block;
    width: 100%;
    font-size: 12px;
}
.main_ctr .ctt_form .form label {
    margin-bottom: 6px;
    font-weight: 500;
}
.main_ctr .ctt_form .form label span {
    color: #009bff;
}
.main_ctr .ctt_form .form input,
.main_ctr .ctt_form .form textarea {
    min-height: 32px;
    margin-bottom: 16px;
    padding: 0;
    border: 1px solid #868e96;
}
.main_ctr .ctt_form .ctt_submit {
    text-align: right;
}
.main_ctr .ctt_form .ctt_submit button {
    background: #009bff;
}

/* map */
.main_ctr .ctt_form .ctt_map {
    margin-top: 40px;
}
.main_ctr .ctt_form .ctt_map iframe {
    margin-bottom: 8px;
}
.main_ctr .ctt_form .ctt_map p {
    line-height: 18px;
    font-size: 12px;
}

/* 테블릿용 CSS */
@media all and (min-width: 768px) {
}

/* PC용 CSS */
@media all and (min-width: 960px) {
    .main_ctr .title,
    .main_ctr .title_content {
        text-align: left;
    }

    /* sec 1 */
    .main_ctr .main_top_1 {
        margin: 90px auto;
    }
    .main_ctr .main_top_1 p.title_content {
        margin: 58px 0 0;
    }
    .main_ctr .main_top_1_1 {
        width: 500px;
    }
    .main_ctr .main_top_1_1 .bg--yellow7 {
        top: 0;
        left: -24px;
        height: 180px;
        background-size: 180px 180px;
    }
    .main_ctr .main_top_1_1 .title {
        padding-top: 40px;
        font-size: 48px;
        line-height: 64px;
        letter-spacing: -1.44px;
    }
    .main_ctr .main_top_1_1 .title_content {
        width: 503px;
    }
    .main_ctr .main_top_1_2 {
        display: inline-block;
        width: calc(100% - (500px));
        vertical-align: middle;
    }
    .main_ctr .main_top_1_2 .content_img {
        margin-top: 0px;
        padding-left: calc(100% - 460px);
    }
    .main_ctr .main_top_1_2 .content_img img {
        width: 100%;
        max-width: 460px;
    }

    /* sec 2 */
    .main_ctr .main_top_2 {
        width: calc(100% - 48px);
        max-width: 1192;
        margin: 0 auto;
    }
    .main_ctr .main_top_2_1 {
        width: 100%;
        max-width: 1192px;
        margin: 0 auto;
        padding-top: 240px;
    }
    .main_ctr .main_top_2_1 .title {
        display: inline-block;
        width: 377px;
        padding-top: 0;
        font-size: 48px;
        line-height: 64px;
        letter-spacing: -1.44px;
    }
    .main_ctr .main_top_2_1 .title_content {
        display: inline-block;
        width: calc(100% - (377px + 11px + 10.32258065%));
        margin: 0px;
        padding-left: 10.32258065%;
        padding-right: 11px;
        text-align: left;
        vertical-align: middle;
    }
    .main_ctr .main_top_2_2 {
        width: 100%;
        max-width: 1192px;
        margin: 133px auto 0px;
    }
    .main_ctr .main_top_2_2 ul li {
        display: inline-block;
        width: calc((100% - (40px)) / 3);
        height: 400px;
        margin-left: 20px;
        padding-left: 0px;
    }
    .main_ctr .main_top_2_2 ul li:first-child {
        margin-left: 0px;
    }
    .main_ctr .main_top_2_2_1 {
        background-image: url(../../images/image-main-1-w@3x.png);
        background-image: -webkit-image-set(
            url(../../images/image-main-1-w.webp) 1x,
            url(../../images/image-main-1-w@2x.webp) 2x,
            url(../../images/image-main-1-w@3x.webp) 3x
        );
        background-image: image-set(
            url(../../images/image-main-1-w.webp) 1x,
            url(../../images/image-main-1-w@2x.webp) 2x,
            url(../../images/image-main-1-w@3x.webp) 3x
        );
    }
    .main_ctr .main_top_2_2_2 {
        background-image: url(../../images/image-main-2-w@3x.png);
        background-image: -webkit-image-set(
            url(../../images/image-main-2-w.webp) 1x,
            url(../../images/image-main-2-w@2x.webp) 2x,
            url(../../images/image-main-2-w@3x.webp) 3x
        );
        background-image: image-set(
            url(../../images/image-main-2-w.webp) 1x,
            url(../../images/image-main-2-w@2x.webp) 2x,
            url(../../images/image-main-2-w@3x.webp) 3x
        );
    }
    .main_ctr .main_top_2_2_3 {
        background-image: url(../../images/image-main-3-w@3x.png);
        background-image: -webkit-image-set(
            url(../../images/image-main-3-w.webp) 1x,
            url(../../images/image-main-3-w@2x.webp) 2x,
            url(../../images/image-main-3-w@3x.webp) 3x
        );
        background-image: image-set(
            url(../../images/image-main-3-w.webp) 1x,
            url(../../images/image-main-3-w@2x.webp) 2x,
            url(../../images/image-main-3-w@3x.webp) 3x
        );
    }
    .main_ctr .main_top_2_2 .title {
        padding-top: 285px;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.72px;
    }
    .main_ctr .main_top_2_2 .title_content {
        width: 110%;
        margin-top: 12px;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }

    /* sec 3 */
    .main_ctr .main_top_3 {
        margin-bottom: 280px;
    }
    .main_ctr .main_top_3 .title {
        padding: 0px;
    }
    .main_ctr .main_top_3 .tab {
        margin-top: 100px;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
        text-align: left;
    }
    .main_ctr .main_top_3 ul.tab li {
        height: 34px;
        margin-right: 60px;
        line-height: 32px;
    }
    .main_ctr .main_top_3 .service_sec {
        margin-top: 80px;
    }
    .main_ctr .main_top_3 .service_sec .service_img {
        display: inline-block;
        width: 48.65771812%; /* 580 / 1192 */
        max-width: 580px;
        height: 513px;
        vertical-align: middle;
    }
    .main_ctr .main_top_3 .service_sec .service_content {
        display: inline-block;
        width: 42.95302013%; /* (1192 - 100 - 580) / 1192 */
        max-width: 512px;
        margin-top: 0px;
        margin-left: 8.389261745%; /* 100 / 1192 */
        vertical-align: top;
    }
    .main_ctr .main_top_3 .service_sec .service_content .title {
        font-size: 36px;
        line-height: 56px;
        letter-spacing: -1.08px;
    }
    .main_ctr .main_top_3 .service_sec .service_content .title img {
        width: 60px;
        margin-right: 20px;
    }
    .main_ctr .main_top_3 .service_sec .service_content .title .comingsoon {
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -1.72px;
    }
    .main_ctr .main_top_3 .service_sec .service_content .title_content {
        width: 100%;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .main_ctr .main_top_3 .service_sec .service_content ul {
        margin-top: 60px;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.72px;
        font-weight: bold;
    }
    .main_ctr .main_top_3 .service_sec .service_content ul li {
        margin-bottom: 16px;
    }
    .main_ctr .main_top_3 .service_sec .service_content ul li span:first-child {
        width: 30px;
        color: #dee2e6;
    }
    .main_ctr .main_top_3 .service_sec .service_content ul li span:nth-child(2) {
        width: calc(100% - 45px);
    }

    /* sec 4 */
    .main_ctr .main_top_4 {
        height: 220px;
    }
    .main_ctr .main_top_4 .bg_wide_rect {
        height: 167px;
    }
    .main_ctr .main_top_4 .main_top_4_content {
        max-width: 1240px;
        cursor: default;
    }
    .main_ctr .main_top_4 .main_top_4_content .title_content {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .main_ctr .main_top_4 .main_top_4_content .title {
        font-size: 36px;
        line-height: 56px;
        letter-spacing: -1.08px;
    }
    .main_ctr .main_top_4 .main_top_4_left {
        width: calc(100% - 488px);
        height: 167px;
        margin-left: 84px;
    }
    .main_ctr .main_top_4 .main_top_4_left .main_top_4_flex {
        height: 167px;
    }
    .main_ctr .main_top_4 .main_top_4_right {
        top: -80px;
        width: 390px;
    }
    .main_ctr .main_top_4 .main_top_4_right img {
        width: 350px;
    }
    .main_ctr .main_top_4 .show_more {
        max-width: 1240px;
        margin: 0 auto;
    }
    .main_ctr .main_top_4 .show_more a {
        display: block;
        width: calc(100% - 168px);
        margin: 12px auto 0;
        text-align: left;
    }

    /* sec 5 */
    .main_ctr .main_top_5 ul {
        margin-top: 120px;
    }
    .main_ctr .main_top_5 ul li {
        margin-bottom: 32px;
    }

    /* sec 6 */
    /* .main_ctr .main_top_6 ul {
        height: 610px;
        margin-top: 100px;
        padding-top: 22px;
    }
    .main_ctr .main_top_6 ul li {
        width: 384px;
        height: 574px;
        margin-right: 20px;
        box-shadow: 5px 5px 30px #00000029;
    }
    .main_ctr .main_top_6 .news_img {
        width: 384px;
        height: 240px;
    }
    .main_ctr .main_top_6 .news_txt_cnt {
        padding: 40px;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
    }
    .main_ctr .main_top_6 .news_txt_cnt .news_title {
        height: 72px;
        margin-top: 12px;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.72px;
        font-weight: bold;
    }
    .main_ctr .main_top_6 .news_txt_cnt .news_title_content {
        height: 61px;
        margin-top: 12px;
    }
    .main_ctr .main_top_6 .news_txt_cnt .news_date {
        margin-top: 40px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.48px;
    } */

    /* sec 7 */
    .main_ctr .bg--blue5 {
        display: block;
    }
    .main_ctr .ctt_tit {
        margin-top: 100px;
    }
    /* form */
    .main_ctr .ctt_form form {
        order: 2;
        width: 44%;
        margin-left: 8.5%;
    }
    .main_ctr .ctt_form .form label,
    .main_ctr .ctt_form .form input,
    .main_ctr .ctt_form .form textarea {
        font-size: 16px;
    }
    .main_ctr .ctt_form .form input {
        height: 48px;
        margin-bottom: 20px;
    }
    .main_ctr .ctt_form .form textarea {
        margin-bottom: 40px;
    }
    /* map */
    .main_ctr .ctt_form {
        display: flex;
    }
    .main_ctr .ctt_form .ctt_map {
        order: 1;
        width: 56%;
        margin: 0;
    }
    .main_ctr .ctt_form .ctt_map iframe {
        height: 474px;
    }
    .main_ctr .content .ctt_map p {
        font-size: 16px;
        line-height: 24px;
    }
}
